<script>
  import Line from "frontendcommon/src/components/charts/base/Line";

  export default {
    name: "line-naf",
    extends: Line,
    mounted() {
      Chart.defaults.global.defaultFontSize = 10;
      Chart.defaults.global.defaultFontColor = "#A6ACBE";
      this.renderChart(this.chartData, this.options);
      // const Legend = this.$data._chart.generateLegend();
      // this.$emit('generated', Legend)
    },
  };
</script>
