import axios from 'axios'
import store from '../store/index.js'

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_ENDPOINT,
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials' : 'true',
        'Content-Type': 'application/json;charset=utf-8',
    }
});

const requestInterceptor = (config) => {
    if (store.getters.getAccessToken != null) {
        config.headers['Authorization'] = "Bearer " + store.getters.getAccessToken;
    }
    /*if (config.method === 'post') {
        console.log("store.getters.getApiConfigInfo",store.getters.getApiConfigInfo)
        if (typeof config.data === 'undefined') {
            config.data = store.getters.getApiConfigInfo
        } else {
            let extend = (a, b, url) => {
                var c = {}
                for (var keyA in a){
                    c[keyA] = a[keyA]
                }
                for (var keyB in b){
                    if (typeof a === "undefined" || !a.hasOwnProperty(keyB) || keyB == 'unid_o_fact' || (url == '/comercial/farmacia' && keyB == 'id_usuario'))
                        c[keyB] = b[keyB]
                }            
                
                return c
            }

            config.data = extend(store.getters.getApiConfigInfo,config.data);

        }
    }*/
    return config;
};

const responseInterceptor = (response) => {
    return response
} 

httpClient.interceptors.request.use(requestInterceptor);
httpClient.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if(error.response.status === 401) {
      store.dispatch('destroyStore')
      window.location='/login'
    }
    return Promise.reject(error)
})

export default httpClient;