<template>
  <modal size="lg" @hide="$emit('close')" paddingSize="sm">
    <template>
      <div id="product-modal">
        <div class="product-modal-content">
          <b-row class="mt-4" v-if="product != null">
            <b-col lg="3">
              <div class="product-modal-image">
                <img :src="product.urlFoto" alt="" />
              </div>
            </b-col>
            <b-col lg="7" v-if="infoArticulo != null && product != null" class="col-xl-7">
              <div class="product-modal-text">
                <div class="d-flex align-items-center product-modal-text-bar">
                  <SimpleBullet :width="200" :color="simpleBulletColor" :height="10" :percent="product.cobertura * 100" class="mr- 3" />
                </div>
                <p class="product-modal-text-title mt-3">
                  <strong>{{ infoArticulo.codigo }}</strong>
                  {{ infoArticulo.descripcion }}
                </p>
                <p>{{ $t("generals.subfamily") }}: {{ infoArticulo.tipo_articulo_desc }} / {{ infoArticulo.subfamilia }}</p>
                <p>{{ $t("generals.lab") }}: {{ infoArticulo.laboratorio }}</p>
              </div>
            </b-col>
            <b-col lg="2">
              <div class="product-modal-right-boxes">
                <div class="product-modal-right-box">
                  <span>Nº {{ product.rank1 }}</span>
                  <p>en la red</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="d-flex align-items-center mt-4 mb-4" v-if="infoArticulo != null && product != null">
          <h4>{{ $t("generals.sellsData") }}</h4>
        </div>
        <div class="product-modal-prices-boxes mb-5" v-if="infoArticulo != null && product != null">
          <card-price v-if="infoArticulo.pvp_optimos.length > 0" :title="$t('product.pvpOptimNet')" :number="$n(infoArticulo.pvp_optimos[0].pvp_optimo, 'currency')" />

          <card-price :title="$t('product.dtoOptim')" :number="$n(product.dto_minimo / 100, 'percentDecimal')" />
          <card-price :title="$t('dashboard.home.analisisStock.daysStock')" :number="$n(infoArticulo.diasstockmedio, 'unidades')" :unit="$t('generals.days')" />
          <card-price :title="$t('generals.fact')" :number="$n(product.dato, 'currency')" />
          <card-price :title="$t('product.cuota')" :number="$n(product.cuota, 'percentDecimal')" :footer="$n(product.incrementoCuota, 'percentDecimal') + ' incremento'" />
          <card-price :title="$t('product.cobertura')" :number="$n(product.cobertura, 'percentDecimal')" :footer="$n(product.incrementocobertura, 'percentDecimal') + ' incremento'" />
        </div>

        <distribucion-p-v-p v-if="pvpData && sellsData && product" :data="pvpData" :product="product" :sellData="sellsData.datosRed" :datosRedPVPOptimo="sellsData.datosRedPVPOptimo" />
        <venta-online v-if="pvpData && pvpData.datosOnline.length > 0" :data="pvpData.datosOnline" />
        <gastos-envio-online v-if="pvpData && pvpData.datosOnline.length > 0" />
      </div>
    </template>
  </modal>
</template>

<script>
  import buscador from "@/api/buscador";
  import Search from "./../../../classes/Search";

  import { SimpleBullet } from "frontendcommon";
  import numberFormatter from "@/mixins/numberFormatter";

  import CardPrice from "./components/CardPrice.vue";

  import DistribucionPVP from "./components/DistribucionPVP.vue";
  import VentaOnline from "./components/VentaOnline.vue";
  import GastosEnvioOnline from "./components/GastosEnvioOnline.vue";

  export default {
    name: "modal-product-information",
    extends: buscador,
    mixins: [numberFormatter],
    props: ["codigoUnnefarProduct"],
    components: {
      VentaOnline,
      SimpleBullet,
      CardPrice,
      DistribucionPVP,
      GastosEnvioOnline,
    },
    data() {
      return {
        sellsData: null,
        pvpData: null,
        stockData: null,
        numFarmaciasRed: null,
        infoArticulo: null,
        simpleBulletColor: window.colors.brand01,
        product: null,
      };
    },
    created() {
      //this.numFarmaciasRedCall();
      let searchObject = new Search();
      searchObject.nombre = this.codigoUnnefarProduct;
      searchObject.tipo_venta = "TOTAL";
      searchObject.page_size = 1;
      this.search(searchObject);
      this.detallesArticuloCall();
      this.distVentasProductCall();
      this.distPVPProductCall();
    },
    methods: {
      search: function (searchObject) {
        let searchPromise = new Promise(resolve => {
          this.searchCallApi(resolve, searchObject);
        });
        searchPromise.then(data => {
          this.product = data[0];
        });
      },
      distVentasProductCall: function () {
        let distVentasPromise = new Promise(resolve => {
          this.distVentasProductCallApi(resolve, this.codigoUnnefarProduct);
        });
        distVentasPromise.then(data => {
          this.sellsData = data;
          console.log("sellsData", this.sellsData);
        });
      },
      distPVPProductCall: function () {
        let distPVPProductPromise = new Promise(resolve => {
          this.distPVPProductCallApi(resolve, this.codigoUnnefarProduct);
        });
        distPVPProductPromise.then(data => {
          this.pvpData = data;
        });
      },
      stockFarmaciaProductCall: function () {
        let stockFarmaciaProductPromise = new Promise(resolve => {
          this.stockFarmaciaProductCallApi(resolve, this.codigoUnnefarProduct);
        });
        stockFarmaciaProductPromise.then(data => {
          this.stockData = data;
        });
      },
      numFarmaciasRedCall: function () {
        let numFarmaciasRedCallPromise = new Promise(resolve => {
          this.numFarmaciasCallApi(resolve);
        });
        numFarmaciasRedCallPromise.then(data => {
          this.numFarmaciasRed = data.datos;
        });
      },
      detallesArticuloCall: function () {
        let detailsProductCallPromise = new Promise(resolve => {
          this.detailsProductCallApi(resolve, this.codigoUnnefarProduct);
        });
        detailsProductCallPromise.then(data => {
          this.infoArticulo = data.datos;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  #product-modal {
    .product-modal-content {
      background: #ffffff;
      box-shadow: 0px 2px 6px rgba(226, 226, 226, 0.5);
      border-radius: 6px;
      .product-modal-image {
        width: 200px;
        height: 220px;
        background-color: #fafafa;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .product-modal-text {
        .product-modal-text-bar {
          span {
            display: inline-block;
            margin-left: 10px;
            font-weight: 500;
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: var(--fc-brand-03);
          }
        }
        .product-modal-text-title {
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 15px;
          color: var(--fc-brand-03);
        }
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #737373;
          margin: 0;
        }
      }
      .product-modal-right-boxes {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          width: 50%;
          margin-left: 25%;
          height: 1px;
          background-color: #e2e2e2;
        }
        .product-modal-right-box {
          flex-basis: 50%;
          height: 50%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #fafafa;
          font-weight: 400;
          span {
            font-size: 30px;
            line-height: 36px;
            font-weight: 500;
            letter-spacing: 0.3px;
            color: var(--fc-brand-03);
            margin-bottom: 10px;
          }
          p {
            margin: 0;
            font-size: 12px;
            line-height: 14px;
            color: #b1b1b1;
            width: 70%;
            text-align: center;
          }
        }
      }
    }
    .product-modal-prices-boxes {
      display: flex;
      align-items: center;
      .product-modal-price-box {
        margin-right: 15px;
      }
    }
  }
</style>
