<template>
    <div id="venta-online">
        <collapse-row title="Gastos envio online">
            <template slot="content">
                <DataTable
                        :items="items"
                        :fields="fields"
                        :itemsPerPage="5"
                />
            </template>
        </collapse-row>
    </div>
</template>

<script>
    export default {
        name: "gastos-envio-online",
        data() {
            return {
                items: [
                    {
                        tienda: 'Carrefour',
                        gastos_envio: '7,00€ (3€ en pedidos +120€ e inferiores a 150€)',
                        gastos_gratuitos: 'Pedidos +150€'
                    },
                    {
                        tienda: 'Dosfarma',
                        gastos_envio: '3,99€ (2,99€ en pedidos +25€ e inferiores a 70€)',
                        gastos_gratuitos: 'Pedidos +70€'
                    },
                    {
                        tienda: 'Druni',
                        gastos_envio: '4,50€',
                        gastos_gratuitos: 'Pedidos +30€'
                    },
                    {
                        tienda: 'El Corte Inglés',
                        gastos_envio: '5,90€',
                        gastos_gratuitos: 'Pedidos +99€'
                    },
                    {
                        tienda: 'FarmaVázquez',
                        gastos_envio: '4,50€',
                        gastos_gratuitos: 'Pedidos +30€'
                    },
                    {
                        tienda: 'Globalpharma',
                        gastos_envio: '4,00€',
                        gastos_gratuitos: 'Pedidos +75€'
                    },
                    {
                        tienda: 'MiFarma',
                        gastos_envio: '3,99€',
                        gastos_gratuitos: 'Pedidos +59€'
                    },
                    {
                        tienda: 'PRIMOR',
                        gastos_envio: '2,95€',
                        gastos_gratuitos: 'Pedidos +25€'
                    },
                    {
                        tienda: 'PromoFarma',
                        gastos_envio: '2,99€',
                        gastos_gratuitos: 'Pedidos +49€'
                    },
                    {
                        tienda: 'Welnia',
                        gastos_envio: '3,99€',
                        gastos_gratuitos: 'Pedidos +49€'
                    }
                ],
                fields: [
                    {key: 'tienda', sortable: true, label: "Tienda", tooltip: ""},
                    {key: 'gastos_envio', sortable: true, label: "Gastos Envío", tooltip: ""},
                    {key: 'gastos_gratuitos', sortable: true, label: "Gastos gratuitos", tooltip: ""},
                ]
            }
        },
    }
</script>

<style scoped>

</style>