class Search {
    //Default Values
    constructor() {
        this.nombre = "";
        this.caracteristicas_clase = [];
        this.caracteristicas_estupe = null;
        this.caracteristicas_forma = [];
        this.caracteristicas_grupo = [];
        this.caracteristicas_nevera = null;
        this.caracteristicas_open = false;
        this.caracteristicas_principio = [];
        this.caracteristicas_psicotro = null;
        this.caracteristicas_receta = null;
        this.tipo_venta = "LIBRE";
        this.categorizacion_categorias = [];
        this.categorizacion_open = false;
        this.categorizacion_subcategorias = [];
        this.categorizacion = null;
        this.datosBasicos_codigo = "";
        this.datosBasicos_nombre = "";
        this.datosBasicos_open = false;
        this.datosCompra_estacionalidadI = null;
        this.datosCompra_estacionalidadO = null;
        this.datosCompra_estacionalidadP = null;
        this.datosCompra_estacionalidadV = null;
        this.page_size = 3;
        this.page = 0;
        this.datosCompra_open = true;
        this.datosCompra_sinstock = null;
        this.datosLaboratorios_laboratorios = [];
        this.datosLaboratorios_open = true;
        this.datosLaboratorios_perteneceaclub = null;
        this.datosLaboratorios_todoslaboratorios = false;
        this.solo_busqueda = false;
    }

    toObject(){
        return {
            caracteristicas_clase: this.caracteristicas_clase,
            caracteristicas_estupe: this.caracteristicas_estupe,
            caracteristicas_forma: this.caracteristicas_forma,
            caracteristicas_grupo: this.caracteristicas_grupo,
            caracteristicas_nevera: this.caracteristicas_nevera,
            caracteristicas_open: this.caracteristicas_open,
            caracteristicas_principio: this.caracteristicas_principio,
            caracteristicas_psicotro: this.caracteristicas_psicotro,
            caracteristicas_receta: this.caracteristicas_receta,
            tipo_venta: this.tipo_venta,
            categorizacion: this.categorizacion,
            categorizacion_open: this.categorizacion_open,
            categorizacion_subcategorias: this.categorizacion_subcategorias,
            tipos: this.tipos,
            datosBasicos_codigo: this.datosBasicos_codigo,
            datosBasicos_nombre: this.nombre,
            datosBasicos_open: this.datosBasicos_open,
            datosCompra_estacionalidadI: this.datosCompra_estacionalidadI,
            datosCompra_estacionalidadO: this.datosCompra_estacionalidadO,
            datosCompra_estacionalidadP: this.datosCompra_estacionalidadP,
            datosCompra_estacionalidadV: this.datosCompra_estacionalidadV,
            page_size: this.page_size,
            page: this.page,
            datosCompra_open: this.datosCompra_open,
            datosCompra_sinstock: this.datosCompra_sinstock,
            datosLaboratorios_laboratorios: this.datosLaboratorios_laboratorios,
            datosLaboratorios_open: this.datosLaboratorios_open,
            datosLaboratorios_perteneceaclub: this.datosLaboratorios_perteneceaclub,
            datosLaboratorios_todoslaboratorios:this.datosLaboratorios_todoslaboratorios,
            solo_busqueda:this.solo_busqueda,
        }
    }
}

export default Search;