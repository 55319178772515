<template>
  <aside id="sidebarnaf" :class="[layout.sidebarOpen ? 'sidebar-open' : 'sidebar-close']">
    <div class="sidebar-content">
      <section class="section-top"  >
        <img class="logo" :src="logoSrc" />
        <img class="logo-mini" :src="logoMiniSrc" />
      </section>
      <nav class="custom-scrollbar-small">
        <NavItem v-for="navItem in navItems" :key="navItem.item.text" :childs="navItem.childs" :item="navItem.item" @click="onClickNav" :target="navItem.item.target" :actualPath="actualPath" :is-child="false">
          <NavItem v-for="childItem in navItem.childs" :key="childItem.item.text" :item="childItem.item" @click="onClickNav" :target="navItem.item.target" :is-child="true" :actualPath="actualPath"></NavItem>
        </NavItem>
      </nav>
      <section class="section-bottom" >
        <div class="crazy-line"></div>
        <user-box @click-logout="logout" :fullName="getUsername" />
      </section>
    </div>
  </aside>

  
  
  
</template>

<script>
  import Sidebar from "frontendcommon/src/components/design-system/Sidebar.vue";
  import { mapActions, mapGetters } from "vuex";

  export default {
    extends: Sidebar,
    name: "sidebar-naf",
    props: {
      logoSrc: String,
      logoMiniSrc: String,
    },
    data() {
      return {
      };
    },
    computed: {
      ...mapGetters(["getUser","getUsername"]),
    },
    methods: {
      ...mapActions(["destroyStore"]),
      showProfile: function () {
        this.$router.push("/mi-perfil");
      },
      logout: function () {
        this.destroyStore();
        window.location.href = "/login";
      },
    },
  };
</script>

<style lang="scss">
  #sidebarnaf {
    .sidebar-content {
      .nav-link.router-link-active.router-link-exact-active {
        color: var(--fc-brand-01-medium) !important;
        border-left: 5px solid var(--fc-brand-01-medium) !important;
      }
      .nav-link:hover {
        color: var(--fc-brand-01-medium) !important;
      }

      .section-bottom {
        cursor: pointer;
      }

      img {
        max-width: 80%;
      }
    }
  }
</style>
