export default {
    methods: {
        getColorFarmacia: function() {
            if (window.location.host.includes('cofas')) {
                return '#d81e41'
            } else if (window.location.host.includes('farmathia')) {
                return '#b4b904'
            } else if (window.location.host.includes('riofarco')) {
                return '#923A7F'
            } else if (window.location.host.includes('novaltia')) {
                return '#660f6e'
            } else if (window.location.host.includes('farmaelite')) {
                return '#00a0d1'
            }
            return '#90c647'
        },
        getColorEmpleado: function() {
            if (window.location.host.includes('cofas')) {
                return '#fc7e96'
            } else if (window.location.host.includes('farmathia')) {
                return '#dbde78'
            } else if (window.location.host.includes('riofarco')) {
                return '#b574a7'
            } else if (window.location.host.includes('novaltia')) {
                return '#e88ff0'
            } else if (window.location.host.includes('farmaelite')) {
                return '#a7e1f3'
            }
            return '#BDEB7E'
        },
        getColorRed: function() {
            if (window.location.host.includes('cofas')) {
                //return '#0077b4';
                //rgb(30, 60, 146)
                return '#1e3c92'
            } else if (window.location.host.includes('farmathia')) {
                return '#512b71'
            } else if (window.location.host.includes('riofarco')) {
                return '#A6C03E'
            } else if (window.location.host.includes('novaltia')) {
                return '#9eab05'
            } else if (window.location.host.includes('farmaelite')) {
                return '#d64f95'
            }
            return '#006eb8'
        },
        getColorPosMedio: function() {
            return '#F1C40F'
        },
        getColorPercentMedio: function() {
            return '#ef6c00'
        },
        getColorSuccess: function() {
            return '#7ec27c'
        },
        getColorWarning: function() {
            return '#f7ce47'
        },
        getColorDanger: function() {
            return '#ed6b75'
        },
        getColorOnline: function() {
            return '#ef6c00'
        },
    }
}
