<template>
  <div class="card-kpi-naf" v-bind:style="{ background: background }">
    <div class="card-kpi-text">
      <p class="card-kpi-title">
        {{ title }}
        <slot name="more-info"> </slot>
      </p>
      <div class="card-aki-number">
        <span v-if="value">{{ value }}</span>
        <span :class="value ? 'percentage' : ''">
          {{ $n(percentage, "percentDecimal") }}
        </span>
      </div>
      <p class="card-kpi-footer">
        {{ $n(price, "currency") }}
      </p>
    </div>
  </div>
</template>

<script>
  import numberFormatter from "@/mixins/numberFormatter";

  export default {
    name: "card-kpi-background",
    mixins: [numberFormatter],
    props: ["title", "background", "value", "percentage", "price"],
  };
</script>

<style lang="scss" scoped>
  .card-kpi-naf {
    width: 100%;
    height: 115px;
    border: 1px solid rgba(197, 199, 205, 0.3);
    border-radius: 6px;
    display: flex;
    .card-kpi-text {
      flex-basis: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 24px;
      * {
        color: #fff;
      }
      .card-kpi-title {
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin: 0;
      }
      .card-aki-number {
        display: flex;
        align-items: center;
        margin: 15px 0;
        span {
          display: block;
          font-weight: 400;
          font-size: 19px;
          line-height: 27px;
          &.percentage {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 10px;
            padding-left: 10px;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 5px;
              width: 1px;
              height: 24px;
              background-color: #e2e2e2;
            }
          }
        }
      }
      .card-kpi-footer {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #fff;
        margin: 0;
      }
    }
  }
</style>
