<template>
    <div class="card-widget">
        <div class="white-card-header">
            <div class="header-left">
                <slot name="header-left"> </slot>
            </div>

            <div class="header-right">
                <slot name="header-right"> </slot>
            </div>
        </div>
       <div class="white-card-body">
           <slot name="content"> </slot>
       </div>
    </div>
</template>

<script>
    export default {
        name: "card-widget",
    }
</script>

<style lang="scss" scoped>
    .card-widget {
        width: 100%;
        border: 1px solid #C5C7CD4D;
        background-color: #fff;
        .white-card-header {
            width: 100%;
            padding: 25px 30px;
            border-bottom: 1px solid rgba(197, 199, 205, 0.3);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
</style>