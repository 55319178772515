<template>
  <div id="searcher">
    <input-box style="width: 300px" :label="$t('search.input')" icon="icon-search-outline" v-model="search" :hasValidation="false" :autofocus="true" :clearIcon="true" v-on:keyup.enter="searchCall" />

    <div class="box-result" v-if="results.length > 0">
      <div class="result" v-for="(result, index) in results" @click="selectProduct(index)">{{ result.codigounnefar }} - {{ result.descripcion }}</div>
      <router-link :to="{ path: '/buscador', query: { name: this.search } }">{{ $t("generals.seeAll") }}</router-link>
    </div>

    <div class="box-result" v-else-if="loading">
      <img class="loading-icon" src="@/assets/loading.gif" alt="" />
    </div>

    <modal-product-information v-if="productSelected" :codigoUnnefarProduct="productSelected" @close="productSelected = null" />
  </div>
</template>

<script>
  import Search from "../classes/Search";
  import ModalProductInformation from "./../components/search/modal/ModalProductInformation.vue";
  import buscador from "@/api/buscador";

  export default {
    name: "searcher",
    extends: buscador,
    components: { ModalProductInformation },
    data() {
      return {
        search: "",
        loading: false,
        searchObject: new Search(),
        productSelected: null,
        results: [],
      };
    },
    methods: {
      searchCall: function () {
        if (this.search.length > 0) {
          this.loading = true;
          this.searchObject.nombre = this.search;
          this.searchObject.solo_busqueda = false;
          let searchPromise = new Promise(resolve => {
            this.searchCallApi(resolve, this.searchObject);
          });
          searchPromise.then(data => {
            this.results = data;
            this.loading = false;
          });
        } else {
          this.$router.push("/buscador");
        }
      },
      selectProduct: function (index) {
        this.productSelected = this.results[index].otroLaboratorio ? null : this.results[index].codigounnefar;
        this.results = [];
        this.search = "";
      },
    },
  };
</script>

<style lang="scss" scoped>
  #searcher {
    position: relative;
    .box-result {
      z-index: 999;
      position: absolute;
      top: 50px;
      right: 0;
      width: 300px;
      background: #ffffff;
      box-shadow: 0px 2px 6px rgba(226, 226, 226, 0.5);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      .result {
        height: 40px;
        padding: 15px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: var(--fc-brand-03);
        -webkit-transition: 0.3s;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: var(--fc-brand-01);
          color: #fff;
        }
      }
      a {
        display: block;
        padding: 15px;
      }
    }
  }
</style>
