<template>
  <Modal @close-modal="closeModal()" :title="title">
      <!--template #header>
        <div><h4>Pedido ref. {{ pedido.num_pedido_origen }}</h4></div>
      </template-->
      <template #content>
          <pedido-detalle :pedido="pedido"></pedido-detalle>
      </template>
  </Modal>
</template>

<script>
 import PedidoDetalle from '../PedidoDetalle';
 import Modal from '../../common/modal';
 export default {
    name: "modal-pedido",
    components: {
      PedidoDetalle, Modal
    },
   /*setup () {
      const title = "Pedido ref. "+this.pedido.num_pedido_origen;

      return {
        title
      };
   },*/
    props:{
      pedido:Object
    },
    methods:{
      closeModal(){
        this.$emit("cerrar-modal")
      }
    },
   data () {
      const title = "Pedido ref. "+this.pedido.num_pedido_origen;
      return {title};
   }

  }
</script>
