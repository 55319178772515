import Dashboard from '../views/Dashboard';
import Login from '../views/Login';
import Home from '../views/dashboard/Home';
import Cctr from '../views/Cctr';

import HistoricoPedidos from '../views/dashboard/HistoricoPedidos';
import Prioridades from "@/views/dashboard/Prioridades";


const routes = [
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: {
            requiresAuth: false,
            isAuth: true
        }
    },
    {
        path: '/',
        component: Dashboard,
        children: [
            {
                path: '',
                component: Home,
                meta: {
                    requiresAuth: true,
                    isAuth: false
                }
            },
            {
                path: 'historico-pedidos',
                component: HistoricoPedidos,
                meta: {
                    requiresAuth: true,
                    isAuth: false
                },
            },
            {
                path: 'prioridades',
                component: Prioridades,
                meta: {
                    requiresAuth: true,
                    isAuth: false
                }
            }
        ]
    },
    {
        path: '/:codigo',
        component: Cctr,
        name: 'cctr',
        meta: {
            requiresAuth: false,
            isAuth: false
        }
    },

];

export default routes;
