<template>
    <a :href="tutorial.href" target="_blank" class="card-tutorial">
        <div class="card-tutorial-image">
            <img :src="require(`${tutorial.image}`)" alt="">
        </div>
        <div class="card-tutorial-text">
            <p class="card-tutorial-title">{{ tutorial.title }}</p>
            <p>{{ tutorial.description }}</p>
        </div>
    </a>
</template>

<script>
    export default {
        name: "card-tutorial",
        props: ['tutorial','image']
    }
</script>

<style lang="scss" scoped>
    .card-tutorial {
        &:hover {
            text-decoration: none;
        }
        .card-tutorial-image {
            width: 100%;
            margin-bottom: 10px;
            img {
                width: 100%;
                height: auto;
            }
        }
        .card-tutorial-text {
            p {
                margin: 0;
                font-weight: 400;
                color: $medium-grey;
                &.card-tutorial-title {
                    font-weight: 500;
                    color: #373F41;
                }
            }
        }
    }
</style>