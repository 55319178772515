<template>
    <div id="dashboard" v-if="load_web">
        <SidebarDashboard />
        <div class="right-part">
            <div :class="{ 'right-part-container' : currentRouteName }">
                <router-view> </router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import auth from '@/api/auth';
    import SidebarDashboard from '@/components/sidebar/SidebarDashboard'
    import userValidations from "@/mixins/userValidations";

    export default {
        name: 'Dashboard',
        mixins: [ userValidations, auth],
        components: {
            SidebarDashboard
        },
        computed: {
            ...mapState(["load_web","user"]),
            currentRouteName() {
                return !this.$route.path.includes('buscador');
            }
        },
        created(){
            let loginPromise = new Promise(resolve => {
                this.logingWithUrl(resolve);
            });

            loginPromise.then(data => {
                if(data){
                    this.saveUser(data.socio);
                    this.saveUsername(data.socio.nombre);
                    this.saveLoadWeb(true);
                    this.$router.push("/");
                }else{
                    this.saveAccessToken(null)
                    this.destroyStore();
                    this.$router.push('/login');
                }
            });
            
            
        },
        methods:{
            ...mapActions(['saveUsername','saveUser','saveLoadWeb','saveCodigoArticulo'])
        }

    }
</script>

<style lang="scss">

    #dashboard {
        margin-left: 260px;
        width: calc(100% - 260px);
        height: 100vh;
        overflow-y: auto;
        .right-part-container {
            width: 100%;
            
            margin: 0 auto;
            
        }
    }

    @media screen and (max-width: 1300px) {
        #dashboard {
            margin-left: 80px;
            width: calc(100% - 80px);
        }
    }

    @media screen and (max-width: 992px) {
        #dashboard {
            margin-left: 60px;
            width: calc(100% - 60px);
        }
    }


</style>
