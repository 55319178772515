<template>
  <div class="singlelineandbarsperiods-container" width="100%"></div>
</template>

<script>
  import colors from "@/mixins/colors";

  export default {
    name: "single-line-and-bars-periods",
    props: {
      data: {
        default: [],
      },
      dataLine: {
        default: 0,
      },
      xLabel: {
        default: "",
      },
      yLabel: {
        default: "",
      },
      height: {
        default: 250,
      },
      numberFormatType: {
        default: "currency",
      },
    },
    mixins: [colors],
    methods: {
      onResize(event) {
        var vm = this;
        setTimeout(function () {
          vm.crearSVG();
        }, 200);
      },
      crearSVG() {
        var margin = { top: 20, right: 20, bottom: 50, left: 50 },
          width = this.$el.getBoundingClientRect().width - margin.left - margin.right,
          height = this.height - margin.top - margin.bottom,
          data = this.data,
          d3 = this.$d3,
          parseTime = this.$d3.timeParse("%m/%Y"),
          widthChart = width,
          legendRectSize = 10,
          legendSpacing = 4,
          numberFormatType = this.numberFormatType,
          vm = this,
          formatNumber = function (a, b) {
            return vm.$n(a, b);
          },
          formatPercent = function (a) {
            return formatNumber(a, "percentDecimal");
          },
          tooltip = d3.select("body .toolTip"),
          paddingBars = 0.2;

        d3.select(this.$el).selectAll("*").remove();

        // append the svg object to the body of the page
        var svg = d3
          .select(this.$el)
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // X axis
        var x = d3
          .scaleBand()
          .range([0, widthChart])
          .domain(
            data.map(function (d) {
              return parseTime(d[0]);
            })
          )
          .padding(paddingBars);

        const axisBottomG = svg
          .append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b %y")));
        axisBottomG.classed("axisBottom", true);

        axisBottomG.selectAll(".tick text").attr("fill", "#b1b1b1");

        // Add Y axis

        var maxY = this.$d3.max(data, function (d) {
          return Math.abs(d[2]);
        });

        var y = d3
          .scaleLinear()
          .domain([maxY * -1.2, maxY * 1.2])
          .range([height, 0]);
        const axisLeft = svg
          .append("g")
          .attr("transform", "translate(0,-10)")
          .call(d3.axisLeft(y).tickFormat(d => formatNumber(d / 100, "percentDecimal")));
        axisLeft.classed("axisBottom", true);
        axisLeft.selectAll(".tick text").attr("fill", "#b1b1b1");

        var xArea = this.$d3
          .scaleTime()
          .range([paddingBars, widthChart - paddingBars])
          .domain(
            this.$d3.extent(data, function (d) {
              return parseTime(d[0]);
            })
          );
        //Area
        svg
          .append("path")
          .datum(data)
          .attr("fill", window.colors.success)
          .attr("fill-opacity", 0.2)
          .attr("stroke", "none")
          .attr(
            "d",
            d3
              .area()
              .x(function (d) {
                return xArea(parseTime(d[0]));
              })
              .y0(y(maxY > 10 ? -10 : maxY * -1))
              .y1(function (d) {
                return y(maxY > 10 ? 10 : maxY);
              })
          );

        // Bars
        svg
          .selectAll("rect")
          .data(data)
          .enter()
          .append("rect")
          .attr("x", function (d) {
            return x(parseTime(d[0]));
          })
          .attr("y", function (d) {
            return d[2] < 0 ? height / 2 : y(d[2]);
          })
          .attr("width", x.bandwidth())
          .attr("height", function (d) {
            return height / 2 - y(Math.abs(d[2]));
          })
          .attr("fill", function (d) {
            return d[2] > 10 || d[2] < -10 ? window.colors.danger : window.colors.success;
          })
          .on("mouseenter", function (d) {
            tooltip
              .style("left", d3.event.pageX - 50 + "px")
              .style("top", d3.event.pageY - 70 + "px")
              .style("display", "inline-block")
              .html(formatNumber(d[1], numberFormatType) + " (" + formatNumber(d[2] / 100, "percentDecimal") + ")");
          })
          .on("mouseout", function (d) {
            tooltip.style("display", "none");
          });
      },
    },
    mounted: function () {
      // this.#el - is the root element in <template>
      // in this case it is <svg> tag

      this.crearSVG();
      this.$root.$on("resize", this.onResize);
    },
  };
</script>

<style lang="scss">
  .singlelineandbarsperiods-container {
    .axisBottom,
    .axisLeft {
      .domain {
        display: none;
      }
      .tick {
        line {
          display: none;
        }
        text {
          //styleName: H8;
          font-family: Atlas Grotesk;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          text-align: center;
          color: #b1b1b1;
        }
      }
    }

    .bar-dist-pvp-tooltip {
      background: var(--fc-brand-03, #3d3f49);
      color: #fff;
      padding: 12px 18px;
      border-radius: 6px;
      z-index: 2000;
      text-align: left;
    }
  }
</style>
