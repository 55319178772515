<template>
  <div class="card-product">
    <div class="card-product-left-image">
      <span class="position" v-if="position"> Nº{{ position }} </span>
      <img :src="image" alt="" />
    </div>
    <div class="card-product-right-info">
      <p class="card-product-title">{{ title }}</p>
      <p>
        Códigos: <span>{{ code }}</span>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "card-product",
    props: ["image", "title", "code", "netNumber", "position"],
  };
</script>

<style lang="scss" scoped>
  .card-product {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    padding: 15px 20px;
    border-top: 1.5px solid #c5c7cd4d;
    .card-product-left-image {
      width: 75px;
      height: 75px;
      position: relative;
      .position {
        position: absolute;
        top: 5px;
        left: 5px;
        padding: 5px;
        background-color: var(--fc-brand-03);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .card-product-right-info {
      padding-left: 15px;
      p,
      span {
        margin-bottom: 0;
        font-size: 13px;
        line-height: 20px;
        color: #b1b1b1;
        &.card-product-title {
          color: var(--fc-brand-01);
          font-weight: 500;
          margin-bottom: 5px;
        }
      }
      span {
        color: var(--fc-brand-03);
      }
    }
  }
</style>
