<template>
    <div>
        <div class="select-custom">
            <p>{{ label }}</p>
            <span class="icon-chevron-down-outline" v-bind:class="{ 'open' : collapse }" @click="collapse = !collapse"></span>
        </div>
        <div v-if="collapse" class="multiple-select-custom-options">
            <div class="multiple-select-custom-subtoption"
                v-for="(option,index) in options"
                v-bind:class="{'active' : option.selected}"
                @click="selected(option.value)">
                {{ option.text }}
            </div>
        </div>
    </div>
</template>

<script>

    import customvmodelMixin from '@/mixins/custom.vmodel.js'

    export default {
        name: "select-custom-simple",
        mixins: [customvmodelMixin],
        props: ['label','options'],
        data() {
            return {
                collapse: false,
                name: ''
            }
        },
        created() {
            this.localValue = this.options[0].value;
            this.name = this.options[0].name;
        },
        methods:{
           selected(opcion){
               this.$emit("select-opcion",opcion)
           }     
        }
    }
</script>

<style lang="scss" scoped>
    .select-custom {
        height: 50px;
        padding: 0 20px;
        box-shadow: 0px 1px 4px rgba(188, 186, 186, 0.25);
        background-color: #fff;
        position: relative;
        border: none;
        outline: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 13px;
            line-height: 16px;
            margin: 0;
            font-weight: 500;
            color: var(--fc-brand-03);
        }
        .icon-chevron-down-outline {
            cursor: pointer;
            font-size: 22px;
            line-height: 22px;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            &.open {
                transform: rotate(180deg);
            }
        }
    }
    .multiple-select-custom-subtoption {
        background-color: #fff;
        white-space: nowrap;
        color: var(--fc-brand-02) !important;
        padding: 9px 12px;
        border-radius: 6px;
        &:hover {
            background: var(--fc-brand-01-soft);
        }
        &.active {
            background-color: var(--fc-brand-01);
            color: #fff !important;
            &:hover {
                background-color: var(--fc-brand-01);
            }
        }
    }
</style>
