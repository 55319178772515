<template>
  <div id="online-sells">
    <collapse-row title="Venta online">
      <template slot="content">
        <div class="online-sells-boxes">
          <div class="online-sell-box" v-for="shop in data">
            <div class="online-sell-box-image align-items-center">
              <img :src="require('./assets/' + shop.tienda_online + '.svg')" />
            </div>
            <div class="online-sell-box-content">
              <p class="shop-title">{{ shop.tienda_online }}</p>
              <p class="price">{{ formatNumber(shop.precio) }}€</p>
              <p class="shipping-cost">{{ formatNumber(shop.gastosenvio) }}€ gastos de envío</p>
            </div>
          </div>
        </div>
      </template>
    </collapse-row>
  </div>
</template>

<script>
  import buscador from "@/api/buscador";
  import numberFormatter from "@/mixins/numberFormatter";

  export default {
    name: "venta-online",
    extends: buscador,
    mixins: [numberFormatter],
    props: ["data"],
    created() {
      console.log("dentro");
      console.log(this.data);
    },
  };
</script>

<style lang="scss" scoped>
  #online-sells {
    .online-sells-boxes {
      display: flex;
      flex-wrap: wrap;
      .online-sell-box {
        width: 255px;
        height: 115px;
        background: #ffffff;
        border: 1px solid rgba(197, 199, 205, 0.3);
        border-radius: 6px;
        margin: 0 20px 20px 0;
        display: flex;
        .online-sell-box-image {
          width: 80px;
          flex-basis: 80px;
          background: #fafafa;
          border-right: 1px solid rgba(197, 199, 205, 0.3);
          border-radius: 6px 0px 0px 6px;
          img {
            position: relative;
            top: calc(100% - 80px);
            left: calc(100% - 65px);
          }
        }
        .online-sell-box-content {
          flex-basis: calc(100% - 80px - 1px);
          width: calc(100% - 80px - 1px);
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p {
            margin: 0;
          }
          .shop-title {
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #3d3f49;
          }
          .price {
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #3d3f49;
          }
          .shipping-cost {
            font-size: 10px;
            line-height: 12px;
            color: #737373;
          }
        }
      }
    }
  }
</style>
