<template>
  <div id="distribucion-pvp-actual-unidades">
    <collapse-row title="Información PVP y ventas">
      <template slot="content">
        <card-widget-graphic-left class="mb-5">
          <template slot="header-left">
            <p class="m-0">Ud. vendidas vs PVP medio de venta</p>
          </template>
          <template slot="header-right">
            <div class="d-flex w-100">
              <div class="header-right-slot-circle mr-3">
                <span :style="'background: #7DAADA'"></span>
                Precios online
              </div>
              <div class="header-right-slot-circle">
                <span :style="'background: ' + colorRed"></span>
                Red
              </div>
            </div>
          </template>

          <template slot="graphic">
            <scatter-dist-pvp :data="data" :formatNumber="$n" />
          </template>

          <template slot="kpi">
            <!-- <card-kpi title="Facturación" valueFormat="currency" :kpiValue="product.datosBuscador.datoFact" :kpiInc="product.datosBuscador.incrementoFact / 100" />
            <card-kpi title="Unidades" :kpiValue="product.datosBuscador.datoUnid" :kpiInc="product.datosBuscador.incrementoUnid / 100" /> -->
          </template>
        </card-widget-graphic-left>
        <card-widget-graphic-left class="mb-5">
          <template slot="header-left">
            <p class="m-0">Ventas por PVP</p>
          </template>
          <template slot="header-right">
            <div class="d-flex w-100">
              <div class="header-right-slot-circle mr-3">
                <span style="background: #3d3f49"></span>
                PVP medio red
              </div>
              <div class="header-right-slot-circle mr-3">
                <span style="background: #7daada"></span>
                PVP óptimo
              </div>
            </div>
          </template>

          <template slot="graphic">
            <bar-dist-pvp :data="data.datosRedPVP" :dataRed="data.datosRedAvg" :pvpoptimo="product.pop" :formatNumber="$n" />
          </template>

          <template slot="kpi">
            <card-kpi title="PVP Moda red" valueFormat="currency" :kpiValue="this.pvpModa[0]" />
          </template>
        </card-widget-graphic-left>
        <card-widget class="mb-5">
          <template slot="header-left">
            <p class="m-0">Distribución de ventas en los últimos 12 meses</p>
          </template>
          <template slot="header-right">
            <div class="d-flex w-100">
              <div class="header-right-slot-circle mr-3">
                <span :style="'background: ' + colorEvolVentas"></span>
                Mi laboratorio
              </div>
            </div>
          </template>

          <template slot="content"> <line-chart-naf v-if="dataSetEvolVentas != null" :data="dataSetEvolVentas" :gradient="false" /></template>
        </card-widget>
        <card-widget class="mb-5">
          <template slot="header-left">
            <p class="m-0">% Desviación PVP Óptimo VS período 27,60€</p>
          </template>
          <template slot="header-right">
            <div class="d-flex w-100">
              <div class="header-right-slot-circle mr-3">
                <span style="background: #3d3f49"></span>
                Fuera de rango
              </div>
              <div class="header-right-slot-circle mr-3">
                <span style="background: #9cc89f"></span>
                En rango
              </div>
              <div class="header-right-slot-circle">
                <span style="background: #3a913f"></span>
                Rango +/- 10%
              </div>
            </div>
          </template>

          <template slot="content">
            <single-line-and-bars-periods v-bind:xLabel="$t('detallearticulo_distVentas_mes')" v-bind:data="datosRedPVPOptimo" v-bind:dataLine="product.pop" />
          </template>
        </card-widget>
      </template>
    </collapse-row>
  </div>
</template>

<script>
  import buscador from "@/api/buscador";

  export default {
    name: "distribucion-pvp",
    extends: buscador,
    props: ["data", "product", "sellData", "datosRedPVPOptimo"],
    data() {
      return {
        preciosonline: null,
        pvpData: null,
        colorRed: window.colors.brand03,
        pvpModa: null,
        dataSetEvolVentas: null,
        colorEvolVentas: window.colors.brand01,
      };
    },
    created() {
      console.log("distribucion-pvp product", this.product);
      console.log("distribucion-pvp sellData", this.sellData);
      console.log("distribucion-pvp data", this.data);
      console.log("distribucion-pvp datosRedPVPOptimo", this.datosRedPVPOptimo);

      this.pvpModa = this.data.datosRedPVP.reduce((prev, current) => (prev[1] > current[1] ? prev : current));

      let dataSet = {
        title: "",
        labels: [],
        datasets: [
          {
            label: this.$t("charts.myLab"),
            fillColor: window.colors.brand01,
            strokeColor: window.colors.brand01,
            pointColor: "#fff",
            pointRadius: 0,
            pointStrokeColor: window.colors.brand01Soft,
            data: [],
            borderColor: window.colors.brand01,
            borderWidth: 2,
            hoverBorderWidth: 5,
            pointHitRadius: 10,
          },
        ],
      };

      console.log("sellData", this.sellData);
      for (let i = 0; i < this.sellData.length; i++) {
        dataSet.labels.push(this.sellData[i][0]);
        dataSet.datasets[0].data.push(this.sellData[i][1]);
      }
      this.dataSetEvolVentas = dataSet;
      console.log("dataSetEvolVentas", this.dataSetEvolVentas);
    },
  };
</script>

<style lang="scss" scoped></style>
