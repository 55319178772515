import {basic, KEY_STORE} from './basic';
const moment = require('moment')

const v1 =  {
    state: {
        ...basic.state,
        storeVersion: 'basic'
    },
    getters: {
        ...basic.getters
    },
    mutations: {
        ...basic.mutations,
        initialiseStore: (state) => {
            basic.mutations.initialiseStore(state);
            if(localStorage.getItem(KEY_STORE)) {
                let stateStore = JSON.parse(localStorage.getItem(KEY_STORE));
                state.storeVersion = stateStore.hasOwnProperty('storeVersion') ? stateStore.storeVersion : state.storeVersion;
            }
            console.log('----------------- Entra v1 -----------------', state.storeVersion);
            if (state.storeVersion == 'basic'){
                console.log("MIGRAR FROM BASIC")
                state.storeVersion = 'v1'

                state.apiConfigInfo.segmento = '';

                if (state.apiConfigInfo.margen == 27.9) state.apiConfigInfo.margen = 30;

                state.apiConfigInfo.fechaDesde = moment().subtract(1, 'months').format('YYYYMM');
                state.apiConfigInfo.fechaHasta = moment().format('YYYYMM');

                if (localStorage.getItem("access_token")){
                    state.access_token = localStorage.getItem("access_token");
                    localStorage.removeItem("access_token");
                }
                
                localStorage.removeItem("firstname");
                basic.mutations.saveStore(state);
            }
        }
    },
    actions: {
        ...basic.actions
    }

};

export default v1;

