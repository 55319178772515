export const KEY_STORE = 'store-app-integrado';
export const basic = {
    state: {
        load_web:false,
        user: null,
        access_token: null,
        username: null,
        cesta:[],
        codigo_articulo:null
    },
    getters: {
        getLoadWeb(state) {
            return state.load_web;
        },
        getAccessToken(state) {
            return state.access_token;
        },
        isAuthenticated(state){
            //Usamos el local storage porque el state devuelve null en router
            if (localStorage.getItem(KEY_STORE)){
                let stateStore = JSON.parse(localStorage.getItem(KEY_STORE));
                return stateStore != null && stateStore.access_token != null;
            }
            return false;
        },
        getUser(state) {
            return state.user;
        },   
        getUsername(state) {
            return state.username;
        },  
        isAdmin(state) {
            return state.user != null ? state.user.rol == 4 : false;
        },
        getCodigoArticulo(state) {
            return state.codigo_articulo;
        }
    },
    mutations: {
        initialiseStore: (state) => {    
            console.log('----------------- Entra basic -----------------');        
            if(localStorage.getItem(KEY_STORE)) {
                let stateStore = JSON.parse(localStorage.getItem(KEY_STORE));
                state.user = stateStore.user;
                state.access_token = stateStore.access_token;
                state.username = stateStore.username;
            }            
        },
        saveStore: (state) => {
            localStorage.setItem(KEY_STORE, JSON.stringify(state));
        },
        updateUser (state, value) {
            state.user = value;
        },
        updateUsername (state, value) {
            state.username = value;
        },
        updateAccessToken (state, value) {
            state.access_token = value;
        },
        updateCodigoArticulo (state, value) {
            state.codigo_articulo = value;
        },
        removeStore: (state) => {
            localStorage.removeItem(KEY_STORE);
        },
        updateLoadWeb(state,value){
            state.load_web=value;
        }
    },
    actions: {
        resetState ({ commit }) {
            commit('resetState');
            commit('saveStore');
        },
        saveUser ({ commit }, value) {
            commit('updateUser', value);
            commit('saveStore');
        },
        saveUsername ({ commit }, value) {
            commit('updateUsername', value);
            commit('saveStore');
        },
        saveAccessToken ({ commit }, value) {
            commit('updateAccessToken', value);
            commit('saveStore');
        },
        saveCodigoArticulo ({ commit }, value) {
            commit('updateCodigoArticulo', value);
            commit('saveStore');
        },
        initialiseStoreAndUser ({ commit }) {
            commit('initialiseStore');
            commit('saveStore');
        },
        destroyStore({ commit }){
            commit('removeStore');
            commit('initialiseStore');
        },
        saveLoadWeb({commit},value){
            commit('updateLoadWeb', value);
            commit('saveStore');
        }

    },
};

export default basic;