<template>
  <div class="list-group-item p-0">
    <div class="row pr-3 pl-3 mb-1 align-items-center">
      <div class="col-12 text-center" style="height: 80px; background-color: #ffffff">
        <img class="logo" :src="getLogoCooperativa" :alt="item.nombre" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DraggableItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed:{
    getLogoCooperativa() {
      return require(`../../../assets/cooperativas/color/${this.item.nombre.toLowerCase()}.svg`);
    }
  }
}
</script>

<style scoped>

</style>
