<template>
  <div class="card-kpi-naf">
    <div class="card-kpi-right-text">
      <div class="card-kpi-title">
        {{ title }}
        <tooltip v-if="showInfo" position="right" show-event="hover">
          <img class="ml-1" @click="$emit('clickInfo')" src="./assets/info.svg" alt="" />
          <template #content>
            <div>
              <slot name="more-info"> </slot>
            </div>
          </template>
        </tooltip>
      </div>
      <div class="card-kpi-number">
        <span>{{ this.$n(this.kpiValue, this.valueFormat) }}</span>

        <span v-bind:class="kpiInc >= 0 ? 'positive-percentage' : 'negative-percentage'" class="percentage">
          {{ (kpiInc > 0 ? "+" : "") + $n(kpiInc, "percentDecimal") }}
          <span v-bind:class="kpiIncIconClass"></span>
        </span>
      </div>
      <div class="w-100 mb-3 card-kpi-footer">
        <p class="mb-1">{{ $t("charts.cuota") }}</p>
        <div class="d-flex" ref="cuotaDiv">
          <SimpleBullet :width="width" :color="colorCuota" :height="10" :percent="cuota * 100" class="mr-3" />
          {{ $n(cuota, "percentDecimal") }}
          <span v-bind:class="cuotaInc >= 0 ? 'positive-percentage' : 'negative-percentage'" class="percentage percentageCuota">
            {{ (cuotaInc > 0 ? "+" : "") + $n(cuotaInc, "percentDecimal") }}
          </span>
        </div>
      </div>

      <div class="w-100 card-kpi-footer">
        <p class="mb-1">{{ $t("charts.cobertura") }}</p>
        <div class="d-flex" ref="coberturaDiv">
          <SimpleBullet :width="width" :color="colorCobertura" :height="10" :percent="cobertura * 100" class="mr-3" />
          {{ $n(cobertura, "percentDecimal") }}
          <span v-bind:class="coberturaInc >= 0 ? 'positive-percentage' : 'negative-percentage'" class="percentage percentageCobertura">
            {{ (coberturaInc > 0 ? "+" : "") + $n(coberturaInc, "percentDecimal") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import numberFormatter from "@/mixins/numberFormatter";
  import { SimpleBullet } from "frontendcommon";

  export default {
    name: "card-kpi-large",
    mixins: [numberFormatter],
    components: { SimpleBullet },
    props: ["title", "valueFormat", "showInfo", "kpiValue", "kpiInc", "kpiIncNet", "cuota", "cobertura", "cuotaInc", "coberturaInc"],
    computed: {
      ...mapGetters(["getApiConfigInfo"]),
      kpiIncIconClass: function () {
        return "kpi-variation-icon " + (this.kpiInc < 0 ? "icon-trending-down-outline" : "icon-trending-up-outline") + " " + (this.kpiInc >= this.kpiIncNet ? "kpi-variation-positive" : "kpi-variation-negative");
      },
    },
    data() {
      return {
        colorCuota: window.colors.brand01,
        colorCobertura: window.colors.brand03,
        width: 80,
      };
    },
    mounted() {
      let vm = this;
      setTimeout(function () {
        vm.width = vm.$refs.cuotaDiv.getBoundingClientRect().width * 0.6;
      }, 500);
    },
  };
</script>

<style lang="scss" scoped>
  .card-kpi-naf {
    width: 100%;
    height: auto;
    background: #ffffff;
    border: 1px solid rgba(197, 199, 205, 0.3);
    border-radius: 6px;
    display: flex;
    .card-kpi-right-text {
      flex-basis: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 24px;
      .card-kpi-title {
        font-weight: 500;
        font-size: 9px;
        line-height: 14px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: var(--fc-brand-03);
        height: 14px;
        display: flex;
        align-items: center;
        margin: 0;
      }
      .card-kpi-number {
        display: flex;
        align-items: center;
        margin: 15px 0 10px 0;
        span {
          display: block;
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          &.percentage {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 10px;
            padding-left: 10px;
            .kpi-variation-icon {
              margin-left: 10px;
            }
            &.negative-percentage {
              color: $red-down;
              .kpi-variation-negative {
                color: $red-down;
              }
              .kpi-variation-positive {
                color: $green-up;
              }
            }
            &.positive-percentage {
              color: $green-up;
              .kpi-variation-negative {
                color: $red-down;
              }
              .kpi-variation-positive {
                color: $green-up;
              }
            }
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 5px;
              width: 1px;
              height: 24px;
              background-color: #e2e2e2;
            }
          }
        }
      }
      .card-kpi-footer {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: $medium-grey;
        margin: 0;
      }
    }

    .percentageCuota,
    .percentageCobertura {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 10px;
      padding-left: 10px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0px;
        width: 1px;
        height: 14px;
        background-color: #e2e2e2;
      }
      &.negative-percentage {
        color: $red-down;
      }
      &.positive-percentage {
        color: $green-up;
      }
    }
  }
</style>
