<template>
  <div id="dropdown-filter">
    <dropdown-filter :key="numberDropdown" title="Filtrar por" :filterToShow="filterToShow" :disableReset="false" @apply-filters="applyFilters" @remove-filters="removeFilters">
      <template>
        <select-box v-if="laboratorioOptions.length > 0" background="dark" label="Laboratorio" :filter="true" v-model="apiConfigInfo.id_laboratorio" :options="laboratorioOptions" @input="changeLaboratorio" />

        <select-box background="dark" label="Objeto de análisis" v-model="apiConfigInfo.unid_o_fact" :options="unid_o_factOptions" @input="changeunid_o_fact" />

        <b-row>
          <b-col :cols="apiConfigInfo.red == '0' ? 12 : 6">
            <select-box background="dark" label="Red de análisis" v-model="apiConfigInfo.red" :options="redOptions" @input="changered" />
          </b-col>

          <b-col cols="6" v-if="apiConfigInfo.red != '0'">
            <select-box background="dark" label="Selector de comunidad" v-model="apiConfigInfo.clave_red" v-if="apiConfigInfo.red == '4'" :options="comunidadesOptions" @input="changeClaveRed" />
            <select-box background="dark" label="Selector de provincia" v-model="apiConfigInfo.clave_red" v-if="apiConfigInfo.red == '5'" :options="provinciasOptions" @input="changeClaveRed" />
          </b-col>
        </b-row>

        <select-box background="dark" label="Segmento" v-model="apiConfigInfo.segmento" :options="segmentoOptions" @input="changeSegmento" />
        <div v-if="!isMobile()">
          <month-picker background="dark" label="Periodo de tiempo - Desde" :months="mesesDesde" :minDate="fechaMin" :maxDate="convertFechaToShow(apiConfigInfo.fechaHasta)" :value="convertFechaToShow(apiConfigInfo.fechaDesde)" @input="changeFechaDesde" />
          <month-picker background="dark" label="Periodo de tiempo - Hasta" :months="mesesHasta" :minDate="convertFechaToShow(apiConfigInfo.fechaDesde)" :maxDate="fechaMax" :value="convertFechaToShow(apiConfigInfo.fechaHasta)" @input="changeFechaHasta" />
        </div>
        <div v-else>
          <input-box background="dark" label="Periodo de tiempo - Desde" v-model="fechaDesdeMobile" />
          <input-box background="dark" label="Periodo de tiempo - Hasta" v-model="fechaHastaMobile" />
        </div>

        <input-box background="dark" label="Margen con el que trabajar" v-model="apiConfigInfo.margen" type="number" step=".1" />
      </template>
    </dropdown-filter>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { mapActions } from "vuex";

  import indicadores from "@/api/indicadores";
  import ApiConfigInfo from "../classes/ApiConfigInfo";

  export default {
    name: "config-filter",
    extends: indicadores,
    computed: {
      ...mapGetters(["getLaboratoriosStore", "getComunidadesStore", "getProvinciasStore", "isAdmin", "getApiConfigInfo"]),
      mesesDesde: function () {
        let fecha = this.$moment(this.fechaMin, "MM/YYYY");
        let meses = [];
        while (fecha.format("YYYYMM") != this.apiConfigInfo.fechaHasta) {
          meses.push(fecha.format("MM/YYYY"));
          fecha = fecha.add(1, "months");
        }
        meses.push(fecha.format("MM/YYYY"));
        return meses;
      },
      mesesHasta: function () {
        let fecha = this.$moment(this.apiConfigInfo.fechaDesde, "YYYYMM");
        let meses = [];
        while (fecha.format("MM/YYYY") != this.fechaMax) {
          meses.push(fecha.format("MM/YYYY"));
          fecha = fecha.add(1, "months");
        }
        meses.push(fecha.format("MM/YYYY"));
        return meses;
      },
    },
    watch: {
      "$store.state.user": {
        immediate: true,
        handler() {
          if (this.isAdmin) {
            this.initLaboratorios();
          } else {
            this.initComunidades();
            this.initProvincias();
          }
        },
      },
      fechaDesdeMobile: {
        inmediate: true,
        handler() {
          if (!this.mesesDesde.includes(this.fechaDesdeMobile)) this.fechaDesdeMobile = this.mesesDesde[0];
          this.apiConfigInfo.fechaDesde = this.$moment(this.fechaDesdeMobile, "MM/YYYY").format("YYYYMM");
        },
      },
      fechaHastaMobile: {
        inmediate: true,
        handler() {
          if (!this.mesesHasta.includes(this.fechaHastaMobile)) this.fechaHastaMobile = this.mesesHasta[this.mesesHasta.length - 1];
          this.apiConfigInfo.fechaHasta = this.$moment(this.fechaHastaMobile, "MM/YYYY").format("YYYYMM");
        },
      },
    },
    data() {
      return {
        numberDropdown: 0,
        filterToShow: "",
        fechaMin: null,
        fechaMax: null,
        fechaDesdeMobile: "",
        fechaHastaMobile: "",
        apiConfigInfo: new ApiConfigInfo(),
        unid_o_factOptions: [
          {
            text: "Facturación PVP IVA",
            value: "1",
            disabled: false,
          },
          {
            text: "Unidades",
            value: "0",
            disabled: false,
          },
        ],
        redOptions: [
          {
            text: "Todas",
            value: "0",
            disabled: false,
          },
          {
            text: "Comunidad",
            value: "4",
            disabled: false,
          },
          {
            text: "Provincia",
            value: "5",
            disabled: false,
          },
        ],
        laboratorioOptions: [],
        comunidadesOptions: [],
        provinciasOptions: [],

        segmentoOptions: [
          {
            value: "",
            text: "Todas",
          },
          {
            value: "SV_ORO",
            text: "Oro (Facturación)",
          },
          {
            value: "SV_PLATA",
            text: "Plata (Facturación)",
          },
          {
            value: "SV_BRONCE",
            text: "Bronce (Facturación)",
          },
          {
            value: "SC_ORO",
            text: "Oro (Cuota)",
          },
          {
            value: "SC_PLATA",
            text: "Plata (Cuota)",
          },
          {
            value: "SC_BRONCE",
            text: "Bronce (Cuota)",
          },
        ],
        provinciasOptions: [],
      };
    },
    created() {
      this.initResetFilter(false);
      this.fechaMin = this.$moment().subtract(12, "months").format("MM/YYYY");
      this.fechaMax = this.$moment().format("MM/YYYY");
    },
    methods: {
      ...mapActions(["saveApiConfigInfoFilter", "saveLaboratorios", "saveProvincias", "saveComunidades"]),
      changeunid_o_fact: function (value) {
        this.apiConfigInfo.unid_o_fact = value;
      },
      changered: function (value) {
        this.apiConfigInfo.red = value;
        this.apiConfigInfo.clave_red = "";
      },
      changeClaveRed: function (value) {
        this.apiConfigInfo.clave_red = value;
      },
      changeSegmento: function (value) {
        this.apiConfigInfo.segmento = value;
      },
      changeLaboratorio: function (value) {
        this.apiConfigInfo.id_laboratorio = value;

        this.saveComunidades([]).then(this.initComunidades());
        this.saveProvincias([]).then(this.initProvincias());
      },
      convertFechaToShow: function (value) {
        return this.$moment(value, "YYYYMM").format("MM/YYYY");
      },

      changeFechaDesde: function (value) {
        this.apiConfigInfo.fechaDesde = this.$moment(value, "MM/YYYY").format("YYYYMM");
      },
      changeFechaHasta: function (value) {
        this.apiConfigInfo.fechaHasta = this.$moment(value, "MM/YYYY").format("YYYYMM");
      },
      initLaboratorios: function () {
        let laboratoriosStore = this.getLaboratoriosStore;
        console.log("laboratoriosStore", laboratoriosStore);
        if (laboratoriosStore.length == 0) this.getLaboratoriosApi();
        else this.laboratorioOptions = laboratoriosStore;
      },
      initComunidades: function () {
        let comunidadesStore = this.getComunidadesStore;
        console.log("comunidadesStore", comunidadesStore);
        if (comunidadesStore.length == 0) this.getComunidadesApi();
        else this.comunidadesOptions = comunidadesStore;
      },
      initProvincias: function () {
        let provinciasStore = this.getProvinciasStore;
        console.log("provinciasStore", provinciasStore);
        if (provinciasStore.length == 0) this.getProvinciasApi();
        else this.provinciasOptions = provinciasStore;
      },
      initResetFilter: function (emitChange) {
        this.filterToShow = "";

        this.apiConfigInfo.fechaDesde = this.getApiConfigInfo.fechaDesde;
        this.apiConfigInfo.fechaHasta = this.getApiConfigInfo.fechaHasta;
        this.apiConfigInfo.unid_o_fact = this.getApiConfigInfo.unid_o_fact;
        this.apiConfigInfo.red = this.getApiConfigInfo.red;
        this.apiConfigInfo.clave_red = this.getApiConfigInfo.clave_red;
        this.apiConfigInfo.segmento = this.getApiConfigInfo.segmento;
        this.apiConfigInfo.margen = this.getApiConfigInfo.margen;
        this.apiConfigInfo.id_laboratorio = this.getApiConfigInfo.id_laboratorio;

        this.fechaDesdeMobile = this.convertFechaToShow(this.getApiConfigInfo.fechaDesde);
        this.fechaHastaMobile = this.convertFechaToShow(this.getApiConfigInfo.fechaHasta);

        this.apiConfigInfo.unid_o_fact = this.getActiveOptionValue(this.unid_o_factOptions, this.apiConfigInfo.unid_o_fact);
        this.apiConfigInfo.red = this.getActiveOptionValue(this.redOptions, this.apiConfigInfo.red);
        this.filterToShow += this.getActiveOptionText(this.unid_o_factOptions, this.apiConfigInfo.unid_o_fact) + " - ";
        this.filterToShow += this.getActiveOptionText(this.redOptions, this.apiConfigInfo.red) + " - ";
        this.filterToShow += this.convertFechaToShow(this.apiConfigInfo.fechaDesde) + " " + this.convertFechaToShow(this.apiConfigInfo.fechaHasta);

        if (this.getApiConfigInfo.id_laboratorio != null) {
          this.initComunidades();
          this.initProvincias();
        }

        if (emitChange) this.$emit("change");
      },
      getActiveOptionValue(options, selectedOption) {
        let optionFilter = options.filter(option => option.value == selectedOption);
        selectedOption = optionFilter.length == 0 ? options[0].value : selectedOption;
        return optionFilter.length == 0 ? options[0].value : optionFilter[0].value;
      },
      getActiveOptionText(options, selectedOption) {
        let optionFilter = options.filter(option => option.value == selectedOption);
        selectedOption = optionFilter.length == 0 ? options[0].value : selectedOption;
        return optionFilter.length == 0 ? options[0].text : optionFilter[0].text;
      },
      getLaboratoriosApi: function () {
        let getLaboratoriosCallPromise = new Promise(resolve => {
          this.getLaboratorios(resolve);
        });
        getLaboratoriosCallPromise.then(data => {
          let labs = data.laboratorios.sort((a, b) => a.nombre.localeCompare(b.nombre));
          var itemsProcessed = 0;
          this.laboratorioOptions = [];
          labs.forEach(item => {
            this.laboratorioOptions.push({
              text: item["nombre"],
              value: item["id"],
              disabled: false,
            });
            itemsProcessed++;
            if (itemsProcessed === labs.length) {
              this.saveLaboratorios(this.laboratorioOptions);
            }
          });
        });
      },
      getComunidadesApi: function () {
        let getComunidadesCallPromise = new Promise(resolve => {
          this.getComunidades(resolve);
        });
        getComunidadesCallPromise.then(data => {
          let aux = data[0].sort((a, b) => a.nombre.localeCompare(b.nombre));
          var itemsProcessed = 0;
          aux.forEach(item => {
            this.comunidadesOptions.push({
              text: item["nombre"],
              value: item["id_comunidad"],
              disabled: false,
            });
            itemsProcessed++;
            if (itemsProcessed === aux.length) {
              this.saveComunidades(this.comunidadesOptions);
            }
          });
        });
      },
      getProvinciasApi: function () {
        let getProvinciasCallPromise = new Promise(resolve => {
          this.getProvincias(resolve);
        });
        getProvinciasCallPromise.then(data => {
          let aux = data[0].sort((a, b) => a.nombre.localeCompare(b.nombre));
          var itemsProcessed = 0;
          aux.forEach(item => {
            this.provinciasOptions.push({
              text: item["nombre"],
              value: item["id_provincia"],
              disabled: false,
            });
            itemsProcessed++;
            if (itemsProcessed === aux.length) {
              this.saveProvincias(this.provinciasOptions);
            }
          });
        });
      },
      applyFilters: function () {
        console.log("applyFilters 1", this.apiConfigInfo);

        if (this.apiConfigInfo.red == 4 && this.apiConfigInfo.clave_red == "") return;
        this.numberDropdown++;
        this.saveApiConfigInfoFilter(this.apiConfigInfo).then(data => this.initResetFilter(true));
      },
      removeFilters: function () {
        this.apiConfigInfo.fechaDesde = this.$moment().subtract(2, "months").format("YYYYMM");
        this.apiConfigInfo.fechaHasta = this.$moment().subtract(1, "months").format("YYYYMM");
        this.apiConfigInfo.unid_o_fact = "1";
        this.apiConfigInfo.red = "0";
        this.apiConfigInfo.clave_red = "";
        this.apiConfigInfo.segmento = "";
        this.apiConfigInfo.margen = 30;
        console.log("removeFilters this.apiConfigInfo", this.apiConfigInfo);
        this.applyFilters();
      },
      isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>

<style lang="scss">
  #dropdown-filter {
    .inputbox-input {
      color: #fff;
    }
    .icon-funnel-outline {
      color: var(--fc-brand-03);
    }
    .inputbox-icons {
      display: none;
    }
    .selectbox.background-dark .select-filter .icon,
    .selectbox.background-dark .select-filter input {
      color: #333 !important;
    }
  }
</style>
