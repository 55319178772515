<template>
  <b-container class="myChartDiv">
    <b-row>
      <b-col cols="8">
        <slot name="titles"></slot>
        <StackedNaf :background="background" @generated="setLegend" :chart-data="data" :options="options" :css-classes="`height:35vh; width:45vh`" />
      </b-col>
      <b-col cols="4" class="borderleft">
        <b-row style="height: 33%">
          <slot name="bottomright" />
        </b-row>
        <b-row style="height: 33%">
          <b-col cols="12" class="legend-box pt-3">
            <div ref="chartlegend" class="legend-con" v-html="legend" />
          </b-col>
        </b-row>
        <b-row style="height: 33%">
          <slot name="bottomright" />
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import StackedBar from "frontendcommon/src/components/charts/StackedBar.vue";
  import StackedNaf from "./base/StackedNaf";

  export default {
    name: "stacked-bar-naf",
    extends: StackedBar,
    components: { StackedNaf },
    methods: {
      generateTooltip: function (tooltipItem, data) {},
    },
    data() {
      return {
        options: {
          tooltips: {
            mode: "point",
            position: "custom",
            backgroundColor: "#3D3F48",
            bodyFontColor: "#fff",
            bodyFontSize: 10,
            borderColor: "#666870",
            borderWidth: 2,
            bodyAlign: "left",
            bodySpacing: 5,
            xPadding: 40,
            yPadding: 20,
            caretSize: 7,
            displayColors: false,
            callbacks: {
              title: () => {},
              label: function (tooltipItem, data) {
                let index = tooltipItem.datasetIndex;
                let pharma = data.labels[0] + ": " + parseInt(data.datasets[index].data[0]) + "%";
                let net = data.labels[1] + ": " + parseInt(data.datasets[index].data[1]) + "%";
                return [pharma, net];
              },
              footer: () => {},
            },
          },
        },
      };
    },
  };
</script>

<style lang="scss">
  .myChartDiv {
    padding: 0;
  }

  .legend-con ul li {
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #b1b1b1;
  }
</style>
