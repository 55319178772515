<template>
  <div id="doughnut-chart-naf">
    <loader-naf v-if="loading" :width="'180px'" :height="'180px'" :borderRadius="'50%'" />
    <tooltip v-else position="top" show-event="hover">
      <b-row>
        <b-col cols="12">
          <div class="dougnut-chart-naf-content">
            <Doughnut style="height: 180px" :background="background" ref="doughnutchart" @generated="setLegend" :chart-data="data" :options="options" :text="text" />
            <div class="dougnut-chart-naf-center">
              <p>{{ $n(data.datasets[0].data[0] / 100, "percentDecimal") }}</p>
              <span> {{ $n(data.datasets[0].data[1] / 100, "percentDecimal") }}</span>
            </div>
          </div>
        </b-col>
        <b-col cols="12" class="legend-box mt-4" v-if="!hideLegend">
          <div ref="chartlegend" class="legend-con" v-html="legend" />
        </b-col>
      </b-row>
      <template #content>
        <div class="tooltip-content">
          <table style="width: 200px">
            <tr>
              <td>{{ data.labels[0] }}</td>
              <td>{{ $n(data.datasets[0].data[0] / 100, "percentDecimal") }}</td>
            </tr>
            <tr>
              <td>{{ data.labels[1] }}</td>
              <td>{{ $n(data.datasets[0].data[1] / 100, "percentDecimal") }}</td>
            </tr>
          </table>
        </div>
      </template>
    </tooltip>
  </div>
</template>

<script>
  import Doughnut from "frontendcommon/src/components/charts/Doughnut";

  export default {
    extends: Doughnut,
    props: ["loading", "hideLegend"],
    name: "doughnut-chart-naf",
  };
</script>

<style lang="scss">
  #doughnut-chart-naf {
    .target {
      display: block;
    }
    .dougnut-chart-naf-content {
      width: 100%;
      position: relative;
      .dougnut-chart-naf-center {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90px;
        margin-left: -45px;
        height: 70px;
        margin-top: -35px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0px;
          font-weight: 800;
          font-size: 18px;
          line-height: 28px;
          color: #000000;
        }
        span {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #3d3f49;
        }
      }
    }
  }
</style>
