import httpClient from './httpClient';

const END_POINT = '/buscador/';

export default {
    methods: {
        searchCallApi: function (resolve, search) {
            httpClient.post(END_POINT + 'buscar', search.toObject())
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },        
        filterDataCallApi: function (resolve) {
            httpClient.post(END_POINT + 'datosselect')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },  
        getArbolCategorizacionApi: function (resolve, categorizacion) {
            httpClient.post(END_POINT + 'arbolCategorizacion', {
                categorizacion:categorizacion
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },         
        detailsProductCallApi: function (resolve, product) {
            httpClient.post(END_POINT + 'infoarticulo/detalles/' + product)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        distPVPProductCallApi: function (resolve, product) {
            httpClient.post(END_POINT + 'infoarticulo/distpvp/' + product)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        
        distVentasProductCallApi: function (resolve, product) {
            httpClient.post(END_POINT + 'infoarticulo/distventas/' + product)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
    }
}