import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['getUser']),
    },
    methods: {
        isDfg: function() {
            return this.getUser.cooperativa.id == 18;
        },
        isNafarco: function() {
            return this.getUser.cooperativa.id == 17;
        },
        isCofas: function() {
            return this.getUser.cooperativa.id == 8;
        },
        isNovaltia: function() {
            return this.getUser.cooperativa.id == 13;
        },
        isCofano: function() {
            return this.getUser.cooperativa.id == 2;
        },
        isCofarle: function() {
            return this.getUser.cooperativa.is == 4;
        }
    }
}