export default {
    methods: {
        filterArrayOptions: function (array, condition, value) {
            array = typeof array == "undefined" ? [] : array
            let include = array.includes(value);
            if (condition && !include) array.push(value);
            else if (!condition)
              array = array.filter(function (item) {
                return item !== value;
              });
            return array;
          },
    }
}