<template>
    <div>
        <svg :class="{ 'default-color' : !color }" transform="scale(1, -1)" :width="width" :height="height" :fill="color">
            <rect :width="width" :height="height" :rx="roundedBorder" fill-opacity="0.1"/>
            <rect :width="width" :height="total" :rx="roundedBorder"/>
        </svg>
    </div>
</template>

<script>

    import VerticalBullet from "frontendcommon/src/components/charts/svg/VerticalBullet";

    export default {
        name: "vertical-bullet-naf",
        extends: VerticalBullet
    }
</script>

<style scoped>

</style>