<template>
  <div class="scatter-farmacias-container" style="position: relative" width="100%"></div>
</template>

<script>
  export default {
    name: "scatter-farmacias",
    props: ["verdes", "amarillas", "naranjas", "rojas", "colores", "refX", "refY", "coloresActivos"],
    methods: {
      onResize(event) {
        var vm = this;
        setTimeout(function () {
          vm.crearSVG();
        }, 200);
      },
      crearSVG() {
        var vm = this;

        var formatNumber = function (a, b) {
          return vm.$n(a, b);
        };

        this.$d3.select(this.$el).selectAll("*").remove();

        var width = this.$el.getBoundingClientRect().width,
          position = { left: this.$el.getBoundingClientRect().left, top: this.$el.getBoundingClientRect().top - 250 },
          height = 250,
          axiosLeftWith = 40,
          axiosBottomHeight = 20,
          legendSpacing = 4;

        if (width <= 0) return;

        // set the ranges
        var x = this.$d3.scaleLinear().range([0, width - axiosLeftWith]);
        var y = this.$d3.scaleLinear().range([height - axiosBottomHeight, 0]);

        let farmacias = this.verdes.concat(this.amarillas, this.naranjas, this.rojas);
        var calcDomain = [];
        for (var i = 0; i < farmacias.length; i++) {
          calcDomain.push([farmacias[i].cuota, farmacias[i].facttotal]);
        }

        var svg = this.$d3.select(this.$el).append("svg").attr("width", width).attr("height", height);
        var g = svg.append("g").attr("transform", "translate(" + axiosLeftWith + ",0)");

        // Scale the range of the data
        let minX = this.$d3.min(calcDomain, function (d) {
          return d[0];
        });
        let maxX = this.$d3.max(calcDomain, function (d) {
          return d[0];
        });
        let maxY = this.$d3.max(calcDomain, function (d) {
          return d[1];
        });
        let minY = this.$d3.min(calcDomain, function (d) {
          return d[1];
        });
        x.domain([minX, maxX]);
        y.domain([minY, maxY]);

        var tooltip = this.$d3.select(this.$el).append("div").attr("class", "scatter-farmacias-tooltip").style("position", "absolute").style("visibility", "hidden");

        g.selectAll("dot")
          .data(this.verdes)
          .enter()
          .append("circle")
          .attr("r", 4.5)
          .style("fill", this.coloresActivos.includes("verdes") ? this.colores.verdes : this.$d3.scaleLinear().domain([1, 10]).range(["white", this.colores.verdes])(4))
          .style("stroke-width", "0.5")
          .style("stroke", "#FFFFFF")
          .attr("cx", function (d) {
            return x(d.cuota);
          })
          .attr("cy", function (d) {
            return y(d.facttotal);
          });

        g.selectAll("dot")
          .data(this.amarillas)
          .enter()
          .append("circle")
          .attr("r", 4.5)
          .style("fill", this.coloresActivos.includes("amarillas") ? this.colores.amarillas : this.$d3.scaleLinear().domain([1, 10]).range(["white", this.colores.amarillas])(4))
          .style("stroke-width", "0.5")
          .style("stroke", "#FFFFFF")
          .attr("cx", function (d) {
            return x(d.cuota);
          })
          .attr("cy", function (d) {
            return y(d.facttotal);
          });

        g.selectAll("dot")
          .data(this.naranjas)
          .enter()
          .append("circle")
          .attr("r", 4.5)
          .style("fill", this.coloresActivos.includes("naranjas") ? this.colores.naranjas : this.$d3.scaleLinear().domain([1, 10]).range(["white", this.colores.naranjas])(4))
          .style("stroke-width", "0.5")
          .style("stroke", "#FFFFFF")
          .attr("cx", function (d) {
            return x(d.cuota);
          })
          .attr("cy", function (d) {
            return y(d.facttotal);
          });

        g.selectAll("dot")
          .data(this.rojas)
          .enter()
          .append("circle")
          .attr("r", 4.5)
          .style("fill", this.coloresActivos.includes("rojas") ? this.colores.rojas : this.$d3.scaleLinear().domain([1, 10]).range(["white", this.colores.rojas])(4))
          .style("stroke-width", "0.5")
          .style("stroke", "#FFFFFF")
          .attr("cx", function (d) {
            return x(d.cuota);
          })
          .attr("cy", function (d) {
            return y(d.facttotal);
          });

        g.append("line").attr("x1", x(minX)).attr("x2", x(maxX)).attr("y2", y(this.refY)).attr("y1", y(this.refY)).attr("class", "line_ref_y").attr("stroke-width", 1).attr("stroke", "#B1B1B1").attr("stroke-dasharray", 4);
        g.append("line").attr("x1", x(this.refX)).attr("x2", x(this.refX)).attr("y2", y(minY)).attr("y1", y(maxY)).attr("class", "line_ref_x").attr("stroke-width", 1).attr("stroke", "#B1B1B1");

        // Add the X Axis
        const axisBottomG = svg
          .append("g")
          .attr("class", "axisBottom")
          .attr("transform", "translate(" + axiosLeftWith + "," + (height - axiosBottomHeight) + ")")
          .call(
            this.$d3
              .axisBottom(x)
              .ticks(12)
              .tickFormat(d => formatNumber(d, "percent"))
          );
        axisBottomG.classed("axisGrey", true);

        axisBottomG.selectAll(".tick text").attr("fill", "#b1b1b1");

        // text label for the x axis
        const axisLeftG = svg
          .append("g")
          .attr("class", "axisLeft")
          .attr("transform", "translate(" + axiosLeftWith + ",0)")
          .call(
            this.$d3
              .axisLeft(y)
              .ticks(6)
              .tickFormat(d => formatNumber(d, "currencyChart"))
          );
        axisLeftG.classed("axisGrey", true);
        axisLeftG.selectAll(".tick text").attr("fill", "#b1b1b1");

        svg
          .selectAll("line.horizontalGrid")
          .data(y.ticks(8))
          .enter()
          .append("line")
          .attr("class", "horizontalGrid")
          .attr("x1", axiosLeftWith)
          .attr("x2", width)
          .attr("y1", function (d) {
            return y(d);
          })
          .attr("y2", function (d) {
            return y(d);
          })
          .attr("fill", "none")
          .attr("shape-rendering", "crispEdges")
          .attr("stroke", "#E2E2E2")
          .attr("stroke-width", "0.5px")
          .attr("stroke-dasharray", "0 4 0");
      },
    },
    mounted: function () {
      this.crearSVG();
    },
  };
</script>

<style lang="scss">
  .scatter-farmacias-container {
    .axisBottom,
    .axisLeft {
      .domain {
        display: none;
      }
      .tick {
        line {
          display: none;
        }
        text {
          //styleName: H8;
          font-family: Atlas Grotesk;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          text-align: center;
          color: #b1b1b1;
        }
      }
    }

    .scatter-farmacias-tooltip {
      background: var(--fc-brand-03, #3d3f49);
      color: #fff;
      padding: 12px 18px;
      border-radius: 6px;
      z-index: 2000;
      text-align: left;
    }
  }
</style>
