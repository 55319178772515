<template>
    <div class="multiple-select-custom-subtoptions">
        <div class="multiple-select-custom-subtoptions-check">
            <div class="multiple-select-input-search">
                <input type="text" v-model="search" placeholder="Buscar...">
                <span data-v-7bda208a="" class="icon icon-search-outline"></span>
            </div>
            <div class="multiple-select-check" v-for="option in searchSuboptions">
                <input type="checkbox" v-model="values" :value="option.value">
                {{ option.text }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "multiple-select-inner-with-sub-options",
        props: ['suboptions'],
        data() {
            return {
                search: '',
                values: []
            }
        },
        computed: {
            searchSuboptions: function () {
                return this.suboptions.filter((item) => {
                    return item.text.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .multiple-select-custom-subtoptions-check {
        .multiple-select-input-search {
            width: 100%;
            position: relative;
            input {
                width: 100%;
                height: 30px;
                padding: 0 30px 0 15px;
                border: 1px solid #E2E2E2;
                border-radius: 5px;
                box-shadow: none;
                font-size: 13px;
                line-height: 20px;
                color: #3D3F49;
            }
            span {
                position: absolute;
                top: 7px;
                right: 10px;
            }
        }
        .multiple-select-check {
            display: flex;
            align-items: flex-start;
            width: 215px;
            margin-top: 10px;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #3D3F49;
            input {
                margin: 5px 5px 0 0;
            }
        }
    }
</style>
