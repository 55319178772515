class ApiConfigInfo{

    //Default Values
    constructor() {

        this.fechaDesde = null;
        this.fechaHasta = null;
        this.red = '0';
        this.unid_o_fact="1";
        this.clave_red = '';
        this.segmento = '';
        this.margen = 30;
        this.id_laboratorio = null;
    }
}

export default ApiConfigInfo;