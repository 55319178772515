<template>
  <sidebar-naf :navItems="items" :logo-src="getLogoCooperativa" :logo-mini-src="getLogoCooperativa" />
</template>

<script>
  import menu from "@/api/menu";
  import { mapGetters } from "vuex";

  export default {
    name: "sidebar-dashboard",
    extends: menu,
    data() {
      return {
        items: []
      };
    },
    created() {
      this.callMenuApi();
    },
    computed:{
      ...mapGetters(["getUser"]),
      getLogoCooperativa(){
        let cooperativa=this.getUser ? this.getUser.cooperativa.nombre.toLowerCase() : 'unnefar';
        return require(`../../assets/cooperativas/${cooperativa}.svg`);
      }
    },
    methods: {
      callMenuApi: function () {
        let menu = [
          { id: 1, icono: "icon-home-outline", nombre: "Integrado", titulo: "Integrado", ruta: "\/", orden: 1, padre: null, publicado: 1, visible: 1 },
          { id: 2, icono: "icon-shopping-cart-outline", nombre: "Histórico pedidos", titulo: "Histórico pedidos", ruta: "\/historico-pedidos", orden: 2, padre: null, publicado: 1, visible: 1 }
        ];
        if(this.getUser.cooperativa.permitir_prioridades_integrado == 1){
          menu.push({ id: 3, icono: "icon-flip-outline", nombre: "Prioridades", titulo: "Prioridades", ruta: "\/prioridades", orden: 3, padre: null, publicado:1, visible:1})
        }


        this.createMenu(menu);
      },
      createMenu: function (data) {
        data.forEach(menu => {
          if (menu.visible == 1 && menu.padre == null) {
            let menuObject = {
              item: {
                icon: menu.icono,
                text: menu.nombre,
                url: menu.ruta,
              },
            };

            let submenuArray = data.filter(function (submenu) {
              return submenu.padre === menu.id && submenu.visible == 1;
            });
            if (submenuArray.length > 0) {
              menuObject.item.submenu = submenuArray.map(item => ({
                text: item.nombre,
                url: item.ruta,
              }));
            }
            this.items.push(menuObject);
          }
        });
      }
    },
  };
</script>
