<template>
  <div class="card-widget-graphic-left">
    <div class="white-card-header">
      <div class="header-left">
        <slot name="header-left"> </slot>
      </div>
      <div class="header-right">
        <slot name="header-right"> </slot>
      </div>
    </div>
    <div class="white-card-body">
      <div class="left-graphic">
        <slot name="graphic"> </slot>
      </div>
      <div class="right-kpi">
        <slot name="kpi"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "card-widget-graphic-left",
  };
</script>

<style lang="scss">
  .card-widget-graphic-left {
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(197, 199, 205, 0.3);
    border-radius: 6px;
    .white-card-header {
      width: 100%;
      height: 60px;
      padding: 0 30px;
      border-bottom: 1px solid rgba(197, 199, 205, 0.3);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-left {
        min-width: 50%;
      }
    }
    .white-card-body {
      display: flex;
      align-items: center;
      .left-graphic {
        flex-basis: 67%;
        width: 67%;
        padding: 35px 20px;
      }
      .right-kpi {
        flex-basis: 33%;
        width: 33%;
        border-left: 1.5px solid rgba(197, 199, 205, 0.3);
        padding: 0 35px;
        .card-kpi-naf {
          height: auto;
          padding: 35px 0;
          border: none;
          border-bottom: 1.5px solid rgba(197, 199, 205, 0.3);
          .card-kpi-right-text {
            padding: 0;
          }
        }
      }
    }
  }
</style>
