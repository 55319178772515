<template>
  <div id="app"  :style="themeColors">
    <router-view> </router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    name: "App",
    data() {
      return {
        brand01: "#005BBB",
        brand01Dark: "#4d8dd0",
        brand01Medium: "#7facdc",
        brand01Soft: "#e6eff9",
        brand02: "#004690",
        brand03: "#3D3F49",
        brand04: "#FFFFFF",
        loadWeb: false,
      };
    },
    created() {
      window.colors = {
        brand01: this.brand01,
        brand01Dark: this.brand01Dark,
        brand01Medium: this.brand01Medium,
        brand01Soft: this.brand01Soft,
        brand02: this.brand02,
        brand03: this.brand03,
        brand04: this.brand04,
        success: "#75B84E",
        warning: "#E3B428",
        danger: "#B45D55",
      };

    
    },
    computed: {
      themeColors: function () {
        return {
          "--fc-brand-01": this.brand01,
          "--fc-brand-01-dark": this.brand01Dark,
          "--fc-brand-01-medium": this.brand01Medium,
          "--fc-brand-01-soft": this.brand01Soft,
          "--fc-brand-02": this.brand02,
          "--fc-brand-03": this.brand03,
          "--fc-brand-04": this.brand04,
        };
      },
    },
  };
</script>

<style lang="scss">
  @import "frontendcommon/dist/frontendcommon.css";

  * {
    font-family: "Atlas Grotesk";
  }

  .widget-content {
    min-height: auto;
  }
</style>
