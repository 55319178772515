import httpClient from './httpClient';

const END_POINT = '/menu';

export default {
    methods: {
        getMenu: function (resolve) {
            httpClient.get(END_POINT)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
}