<template>
  <div class="multiple-select-custom-subtoptions">
    <div class="multiple-select-custom-subtoptions-check">
      <div class="multiple-select-input-search">
        <input type="text" v-model="search" placeholder="Buscar..." />
        <span data-v-7bda208a="" class="icon icon-search-outline"></span>
        <input type="checkbox" class="check-all" @click="checkedAll()" />
      </div>
      <div class="multiple-select-check" :key="option.value" v-for="option in searchSuboptions">
        <input type="checkbox" :value="option.value" :checked="option.selected" @click="checked(option)" />
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "multiple-select-inner-check",
    props: ["suboptions"],
    data() {
      return {
        search: "",
        options: [],
        checkedAllOn: false,
      };
    },
    created() {
      this.options = this.suboptions.sort((a, b) => (a.selected && b.selected ? 0 : a.selected && !b.selected ? -1 : 1));
    },
    computed: {
      searchSuboptions: function () {
        return this.options
          .filter(item => {
            return item.text.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
          })
          .slice(0, 10);
      },
    },
    methods: {
      checkedAll: function () {
        this.checkedAllOn = !this.checkedAllOn;
        let vm = this;
        this.options.forEach(function (d) {
          d.selected = vm.checkedAllOn;
          vm.$emit("checked", d);
        });
      },
      checked: function (option) {
        option.selected = !option.selected;
        this.$emit("checked", option);
        this.options = this.options.sort((a, b) => (a.selected && b.selected ? 0 : a.selected && !b.selected ? -1 : 1));
      },
    },
  };
</script>

<style lang="scss" scoped>
  .multiple-select-custom-subtoptions-check {
    .multiple-select-input-search {
      width: 100%;
      position: relative;
      input {
        width: calc(100% - 25px);
        height: 30px;
        padding: 0 30px 0 15px;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        box-shadow: none;
        font-size: 13px;
        line-height: 20px;
        color: #3d3f49;
      }
      span {
        position: absolute;
        top: 7px;
        right: 30px;
      }
    }

    input.check-all {
      margin: 5px 0 5px 5px;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      accent-color: var(--fc-brand-01);
    }
    .multiple-select-check {
      display: flex;
      align-items: flex-start;
      width: 215px;
      margin-top: 10px;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #3d3f49;
      input {
        margin: 5px 5px 0 0;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        accent-color: var(--fc-brand-01);
      }
    }
  }
</style>
