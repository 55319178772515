import httpClient from './httpClient';

const END_POINT = '/pedidos/';

export default {
    methods: {
        getPedidos: function (resolve) {
            httpClient.get(END_POINT)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
}