<template>
  <div class="card-search-product">
    <div class="card-search-product-image">
      <img :src="product.imagen2d" alt="" />
    </div>
    <div class="card-search-product-text">
      <div class="card-search-product-header">
        <p class="card-search-product-title">
          <strong>{{ codigo_nacional }}</strong>
          {{ product.descripcion }}
        </p>
      </div>
      <div class="card-search-prices">
        <div class="left">
          <div class="card-search-price" v-if="this.user.cooperativa.precio !== 'Sin precio'"> <!--  v-if="this.user.cooperativa.id_unnefar!=35 && this.user.cooperativa.id_unnefar!=20"  -->
            <span class="icon-IconOutlineeuro" />
            <div v-if="this.user.cooperativa.precio == 'Almacen'">
              <p>{{ $t("product.precio") }}</p>
              <span>{{
                 $n(parseFloat(product.variantes[0]?.pvl || product.pvl), "currency")
                }}</span>
            </div>
            <div v-else> <!-- this.user.cooperativa.precio == 'Unnefar' -->
              <p>{{ $t("product.precio") }}</p>
              <span>{{
                $n(parseFloat(product.pvu || product.pvl), "currency")
              }}</span>
            </div>
          </div>

          <div class="card-search-plazo">
            <span class="icon-car-outline" />

            <div>
              <p>{{ $t("product.plazo") }}</p>
              <span :class="colorPlazo(product.plazo)">{{
                product.plazo.texto
              }}</span>
            </div>
          </div>
        </div>

        <div class="card-search-addtocart">
          <b-button
            v-if="product.sirve !== ' - '"
            size="sm"
            variant="primary"
            @click="addCesta(product)"
          >
            Comprar</b-button
          >
        </div>
    </div>

      <!-- Descuentos descomentar -->
      <!--div v-if="product.config_cooperativas[0]" class="card-search-dto">
          <div v-if="product.config_cooperativas[0].tramos.length>0" v-for="dto in product.config_cooperativas[0].tramos" class="tag">
            <strong>{{ $t("product.tramos_dto") }}:</strong> <span>{{ dto.descuento.toFixed(2) }}% desde {{ dto.cantidad }} unidades ( Precio: {{ ((parseFloat(product.pvu||product.pvl)*(100-dto.descuento.toFixed(2)))/100).toFixed(2) }} € )</span>
          </div>
          <div v-for="dto in product.config_cooperativas[0].condiciones_pedido.tramos_descuento" class="tag">
            <strong>{{ $t("product.tramos_dto") }}:</strong> <span>{{ dto.descuento.toFixed(2) }}% desde {{ dto.cantidad }} unidades ( Precio: {{ ((parseFloat(product.pvu||product.pvl)*(100-dto.descuento.toFixed(2)))/100).toFixed(2) }} € )</span>
          </div>
      </div-->
      <!-- fin comentario -->

    </div>

  </div>
</template>

<script>
import numberFormatter from "@/mixins/numberFormatter";
import { mapMutations, mapState } from "vuex";

export default {
  name: "card-search-product",
  props: ["product"],
  mixins: [numberFormatter],
  methods: {
    ...mapMutations("cesta", ["ADD_ITEM_CESTA", "SET_MOSTRAR_MODAL_CESTA"]),
    addCesta(producto) {
      this.ADD_ITEM_CESTA(producto);
      this.SET_MOSTRAR_MODAL_CESTA(true);
    },
    colorPlazo(plazo) {
      return plazo.id && plazo.id.length ? "plazo" : "plazo_rojo";
    },
  },
  computed:{
    ...mapState(["user"]),
    codigo_nacional(){
      return parseInt(this.product.codigo_nacional);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-search-product {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(226, 226, 226, 0.5);
  border: 1px solid #e2e2e2;
  border-radius: 5px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  .card-search-product-image {
    width: 210px;
    border-right: 1px solid #e2e2e2;
    img {
      width: 200px;
    }

    @media only screen and (max-width: 600px) {
      border-right: 0;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .card-search-product-text {
    width: 100%;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;

    .card-search-product-header {
      .card-search-product-title {
        font-size: 20px;
        line-height: 24px;
        color: var(--fc-brand-03);
        margin-bottom: 0 !important;
      }
      .product-icons-row {
        width: 100px;
        div {
          background: #e2e2e2;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: inline-block;
          text-align: center;

          &.success {
            background: $green-up;
          }

          &.danger {
            background: $red-down;
          }
          img {
            width: auto;
            height: 18px;
            margin-top: 5px;
          }
        }
      }
      .card-search-labels {
        display: flex;
        align-items: center;
        .card-search-label {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          margin-right: 18px;
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 10px;
            background-color: var(--fc-brand-03);
          }
        }
      }
    }

    .card-search-prices {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: 730px) {
        flex-direction: column;
      }
      gap: 16px;

      .left {
        display: flex;
        flex-direction: row;
         gap: 32px;
        @media only screen and (max-width: 730px) {
          flex-direction: column;
           gap: 16px;
        }

        .card-search-price {
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            font-size: 13px;
            line-height: 20px;
            margin: 0;
            color: var(--fc-brand-03);
            font-weight: 600;
          }
          span {
            display: inline-block;
            margin-right: 8px;
          }
        }

        .card-search-plazo {
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            font-size: 13px;
            line-height: 20px;
            margin: 0;
            color: var(--fc-brand-03);
            font-weight: 600;
          }
          span {
            display: inline-block;
            margin-right: 8px;
          }
        }
      }

      .card-search-addtocart {
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: end;
      }
    }
  }
  .percentageCuota,
  .percentageCobertura {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding-left: 10px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0px;
      width: 1px;
      height: 14px;
      background-color: #e2e2e2;
    }
    &.negative-percentage {
      color: $red-down;
    }
    &.positive-percentage {
      color: $green-up;
    }
  }
  .nombre_producto {
    color: #979797;
  }
  .plazo {
    color: #238749;
  }
  .plazo_rojo {
    color: #f91919;
  }
}
</style>
