<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12" class="mt-4">
                <LineChartNaf ref="linechart" @generated="setLegend" :chart-data="data" :options="options" :gradient="gradient"/>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

    import LineChartNaf from './base/LineNaf.vue'
    import Line from 'frontendcommon/src/components/charts/Line.vue';

    export default {
        name: "line-chart-naf",
        extends: Line,
        components: {
            LineChartNaf
        }
    }
</script>

<style scoped>

</style>