import v1 from './v1';
import {KEY_STORE} from './basic';
const moment = require('moment')

const v2 =  {
    state: {
        ...v1.state,
        showTutorial: true
    },
    getters: {
        ...v1.getters,
        
        getShowTutorial(state) {
            return state.showTutorial;
        },
    },
    mutations: {
        ...v1.mutations,
        initialiseStore: (state) => {
            v1.mutations.initialiseStore(state);
            
            console.log('----------------- Entra v2 -----------------', state.storeVersion);
            if (state.storeVersion == 'v1'){
                                
                state.apiConfigInfo.fechaDesde = moment().subtract(2, 'months').format('YYYYMM');
                state.apiConfigInfo.fechaHasta = moment().subtract(1, 'months').format('YYYYMM');

                state.storeVersion = 'v2'
                
                v1.mutations.saveStore(state);
            }

            if(localStorage.getItem(KEY_STORE)) {
                let stateStore = JSON.parse(localStorage.getItem(KEY_STORE));
                state.showTutorial = stateStore.showTutorial
            }
        },             
        updateShowTutorial(state, value) {
            state.showTutorial = value;
        },
    },
    actions: {
        ...v1.actions,
        saveShowTutorial ({ commit }, value) {
            console.log("v2 saveShowTutorial", value)
            commit('updateShowTutorial', value);
            commit('saveStore');
        },
    }

};

export default v2;

