import moment from "moment";
import _ from "lodash";

class Cesta
{
    static save(cesta)
    {
        cesta = { contenido: cesta, expires: Number.parseInt(moment().add(30, 'minutes').format('x')) };
        localStorage.setItem('cesta', JSON.stringify(cesta));
    }

    static remove()
    {
        localStorage.removeItem('cesta');
    }

    static get()
    {
        try {
            let cesta = JSON.parse(localStorage.getItem('cesta'));
            if ( _.isNil(cesta)  || !_.has(cesta, 'contenido') ) //|| Cesta.isExpired(cesta)
                return [];

            return cesta.contenido;
        } catch (e) {
            return [];
        }
    }

    static isExpired(cesta)
    {
        const esta_expirada = moment(_.get(cesta, 'expires') || moment()).isSameOrBefore(moment());
        if ( esta_expirada ) Cesta.remove();
        return esta_expirada;
    }
}

export default Cesta;
