<template>
    <div class="collapse-row">
        <div class="collapse-row-header">
            <h4>{{ title }}</h4>
            <div @click="expand = !expand" class="icon-arrow" v-bind:class="{'expand' : expand}">
                <span class="icon-arrow-ios-downward-outline"></span>
            </div>
        </div>

        <div v-if="expand" class="collapse-row-content">
            <slot name="content"></slot>
        </div>
    </div>

</template>

<script>
    export default {
        name: "collapse-row",
        props: ['title'],
        data() {
            return {
                expand: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .collapse-row {
        width: 100%;
        .collapse-row-header {
            display: flex;
            justify-content: space-between;
            padding: 25px 0;
            border-top: 1px solid #E2E2E2;
            h4 {
                margin: 0;
            }
            .icon-arrow {
                cursor: pointer;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                span {
                    font-size: 20px;
                    line-height: 20px;
                }
                &.expand {
                    transform: rotate(-180deg);
                }
            }

        }
    }
</style>