<template>

  <table>
    <thead v-if="!hideHeader">
      <tr>
        <th v-for="header in filteredCompactFields" :key="header.key" :class="header.class">
          <tooltip position="bottom"
                   show-event="hover"
                   class="tooltip-header"
                   v-if="header.tooltip">
            <span v-if="header.tooltip" :ref="`tp-${header.key}`"/>
            <template #content>
              <span>{{ header.tooltip }}</span>
            </template>
          </tooltip>
          <span>
            {{ header.label }}
          </span>
          <template v-if="header.sortable">
            <span v-if="sortByController === header.key && order === 'desc'" @click="setSort(header.key, 'asc')" class="icon-arrow-ios-upward-outline table-sort-icon"></span>
            <span v-else @click="setSort(header.key, 'desc')" class="icon-arrow-ios-downward-outline table-sort-icon"></span>
          </template>
        </th>
      </tr>
    </thead>
    <tbody v-if="!isFetching">
      <template v-if="!isCompact">
        <tr @click="$emit('onRowClick', item)" v-for="(item, j) in paginatedItems" :key="j">
          <td :id="header.key" v-for="header in filteredCompactFields" :key="header.key + j">
            <slot :name="'cell('+ header.key +')'" :item="item">
              {{ item[header.key] }}
            </slot>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr @click="openCompact(j)" v-for="(item, j) in paginatedItems" :key="j">
          <td :id="header.key" v-for="(header,i) in filteredCompactFields" :key="header.key + j">
            <template v-if="i===0">
              <span v-if="selectedRow === j"/>
              <span v-else/>
            </template>
            <slot :name="'cell('+header.key+')'" :item="item">
              {{ item[header.key] }}
            </slot>
          </td>
          <td v-show="selectedRow === j">
            <div v-for="header in complementaryCompactFields" :key="header.key + j">
              <div>
                <b>{{ header.label }}</b>
              </div>
              <div>
                <slot :name="'cell('+ header.key +')'" :item="item">
                  {{ item[header.key] }}
                </slot>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
    <tbody v-else>
      <strong>Cargando...</strong>
    </tbody>
    <tfoot v-if="!isFetching">
      <div class="flex-pagination">
        <Pagination v-if="items.length > perPage" class="mt-3 content-center" v-model="page" :rows="items.length" :perPage="perPage"/>
      </div>
    </tfoot>
  </table>

</template>

<script>

export default {
  name: "Table",
  props: {
    items: {
      type: Array
    },
    cabeceras: {
      type: Array
    },
    perPage: {
      type: Number,
      default: 10
    },
    sortBy: {
      type: String,
      default: ''
    },
    sortDesc: {
      type: Boolean,
      default: true
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    compactFields: {
      type: String,
      default: ''
    },
    sortFunction: {
      type: Function,
      default: () => false
    },
    gridCols: {
      type: String,
      default: ''
    },
    standAlone: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sortByController: '',
      order: 'desc',
      page: 1,
      selectedRow: null
    }
  },
  mounted () {
    this.sortByController = this.sortBy
    this.order = this.sortDesc ? 'desc' : 'asc'
  },
  watch: {
    items() {
      if(this.page !== 1){
        this.page = 1
      }
    }
  },
  methods: {
    setSort(key, order){
      if(this.sortByController !== key){
        this.sortByController = key
        this.order = 'asc'
      } else {
        this.order = order
      }
    },
    openCompact(j){
      this.selectedRow = this.selectedRow === j ? null : j
    }
  },
  computed: {
    filteredCompactFields () {
      if (this.isCompact) {
        return this.cabeceras.filter(i => this.compactFields.includes(i.key) || i.key === 'actions')
      } else {
        return this.cabeceras
      }
    },
    complementaryCompactFields () {
      return this.cabeceras.filter(i => !this.compactFields.includes(i.key) && i.key !== 'actions')
    },
    hasActionField () {
      return this.cabeceras.some(i => i.key !== 'actions')
    },
    sortedItems () {
      if (this.sortByController.length) {
        const srtFnc = this.sortFunction
        const srtCnt = this.sortByController
        const it = JSON.parse(JSON.stringify(this.items))
        it.sort((a, b) => {
          if (!srtFnc(a, b, srtCnt)) {
            if (typeof a[srtCnt] === 'string' && typeof b[srtCnt] === 'string' && a[srtCnt] && b[srtCnt]) {
              return a[srtCnt].localeCompare(b[srtCnt])
            } else if (typeof a[srtCnt] === 'number' && typeof b[srtCnt] === 'number') {
              return b[srtCnt] - a[srtCnt]
            } else if (!a[srtCnt] && !b[srtCnt]) {
              return 0
            } else if (!a[srtCnt]) {
              return a[srtCnt] === '' ? 1 : -1
            } else if (!b[srtCnt]) {
              return b[srtCnt] === '' ? -1 : 1
            } else {
              if (typeof a[srtCnt].value === 'string' && typeof b[srtCnt].value === 'string' && a[srtCnt].value && b[srtCnt].value) {
                return a[srtCnt].value.localeCompare(b[srtCnt].value)
              } else if (typeof a[srtCnt].value === 'number' && typeof b[srtCnt].value === 'number') {
                return parseFloat(b[srtCnt].value) - parseFloat(a[srtCnt].value)
              } else if (!a[srtCnt].value) {
                return 1
              } else if (!b[srtCnt].value) {
                return -1
              } else {
                const c = JSON.stringify(a[srtCnt])
                const d = JSON.stringify(b[srtCnt])
                return c > d
              }
            }
          } else {
            return srtFnc(a, b, srtCnt)
          }
        })
        return this.order === 'asc' ? it : it.reverse()
      }
      return this.items
    },
    paginatedItems () {
      const from = (this.page - 1) * this.perPage
      const to = this.page * this.perPage
      return this.sortedItems.slice(from, to)
    }
  }
};
</script>

<style lang="scss" scoped>
  .flex-pagination {
    display: flex;
    justify-content: center;
  }
</style>

