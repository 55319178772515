<template>
  <div class="input-custom">
    <span :class="icon"></span>
    <input type="text" :placeholder="placeholder" v-model="localValue" v-on:keyup.enter="eventButton($event)"/>
  </div>
</template>

<script>
  import customvmodelMixin from "@/mixins/custom.vmodel.js";
  export default {
    name: "input-custom",
    mixins: [customvmodelMixin],
    props: ["icon", "placeholder"],
    methods:{
      eventButton(e){
        this.$emit("ON_EVENT_BUTTON",e);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .input-custom {
    width: 100%;
    height: 50px;
    box-shadow: 0px 1px 4px rgba(188, 186, 186, 0.25);
    background-color: #fff;
    position: relative;
    input {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      padding-left: 60px;
      border: none;
      outline: none;
      background-color: transparent;
    }
    span {
      position: absolute;
      top: 50%;
      left: 20px;
      width: 25px;
      height: 25px;
      margin-top: -12.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 23px;
      line-height: 23px;
    }
  }
</style>
