import Vue from 'vue'
import Vuex from 'vuex'
import { common } from 'frontendcommon'
import * as versions from './versions';
import cesta from './cesta'

Vue.use(Vuex);

let getDefaultState = versions.basicVersion;

const state = getDefaultState().state;
const getters = getDefaultState().getters;
const mutations = getDefaultState().mutations;
const actions = getDefaultState().actions;

const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: {
        common,
        cesta
    }
});

export default store;
