<template>
    <div class="product-modal-price-box">
        <p class="product-modal-price-box-title">{{ title }}</p>
        <span class="product-modal-price-number">{{ number }} {{ unit }}</span>
        <span>{{ footer }}</span>
    </div>
</template>

<script>
    export default {
        name: "card-price",
        props: ['title','number','unit','footer']
    }
</script>

<style lang="scss" scoped>
    .product-modal-price-box {
        width: 140px;
        height: 105px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid rgba(197, 199, 205, 0.3);
        border-radius: 4px;
        padding: 20px 10px 20px 20px;
        .product-modal-price-box-title {
            margin: 0;
            font-weight: 500;
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: var(--fc-brand-03);
        }
        .product-modal-price-number {
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: var(--fc-brand-03);
        }
        span {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #737373;
        }
    }
</style>