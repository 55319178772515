import SidebarNaf from './SidebarNaf';
import ConfigFilter from './ConfigFilter';
import Searcher from './Searcher';
import CollapseRow from './CollapseRow';
import ExportExcel from './ExportExcel';

import CardKPI from "./cards/CardKPI";
import CardKPILarge from "./cards/CardKPILarge";
import CardKPIBackground from "./cards/CardKPIBackground";
import CardProduct from './cards/CardProduct';
import CardTutorial from './cards/CardTutorial';
import CardWidget from './cards/CardWidget';
import CardWidgetGraphicLeft from './cards/CardWidgetGraphicLeft';

import InputCustom from './forms/InputCustom';
import SelectCustom from './forms/SelectCustom';
import SelectCustomSimple from './forms/SelectCustomSimple';
import MultipleSelectCustom from './forms/MultipleSelectCustom';

import BardistPVP from "./charts/BardistPVP";
import ScatterFarmacias from "./charts/ScatterFarmacias";
import ScatterDistPVP from "./charts/ScatterDistPVP";
import SingleLineAndBarsPeriods from "./charts/SingleLineAndBarsPeriods";
import StackedBarNaf from "./charts/override/StackedBarNaf";
import DoughnutChartNaf from "./charts/override/DoughnutChartNaf";
import LineChartNaf from "./charts/override/LineChartNaf";
import VerticalBulletNaf from "./charts/override/VerticalBulletNaf";
import BarsWithAverage from "./charts/BarsWithAverage";

export default {
    install: function (Vue) {

        /* General Components */
        Vue.component(SidebarNaf.name, SidebarNaf);
        Vue.component(ConfigFilter.name, ConfigFilter);
        Vue.component(Searcher.name, Searcher);
        Vue.component(CollapseRow.name, CollapseRow);
        Vue.component(ExportExcel.name, ExportExcel);

        /* Cards */
        Vue.component(CardKPI.name, CardKPI);
        Vue.component(CardKPILarge.name, CardKPILarge);
        Vue.component(CardKPIBackground.name, CardKPIBackground);
        Vue.component(CardProduct.name, CardProduct);
        Vue.component(CardTutorial.name, CardTutorial);
        Vue.component(CardWidget.name, CardWidget);
        Vue.component(CardWidgetGraphicLeft.name, CardWidgetGraphicLeft);

        /* Forms */
        Vue.component(InputCustom.name, InputCustom);
        Vue.component(SelectCustom.name, SelectCustom);
        Vue.component(SelectCustomSimple.name, SelectCustomSimple);
        Vue.component(MultipleSelectCustom.name, MultipleSelectCustom);

        /* Charts */
        Vue.component(BardistPVP.name, BardistPVP);
        Vue.component(ScatterDistPVP.name, ScatterDistPVP);
        Vue.component(ScatterFarmacias.name, ScatterFarmacias);
        Vue.component(SingleLineAndBarsPeriods.name, SingleLineAndBarsPeriods);
        Vue.component(StackedBarNaf.name, StackedBarNaf);
        Vue.component(DoughnutChartNaf.name, DoughnutChartNaf);
        Vue.component(LineChartNaf.name, LineChartNaf);
        Vue.component(VerticalBulletNaf.name, VerticalBulletNaf);
        Vue.component(BarsWithAverage.name,BarsWithAverage);
    }
};