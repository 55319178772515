<template>
  <div>
  <Modal @close-modal="closeModal()" :title="title" :btnlabel="btnlabel" :btnFunction="!loading && cesta.items.length && cesta.tipo_envio.length > 0? enviarPedido : ''"> <!--@close-modal="close_modal" v-if="modal_open" -->
    <template #content>
      <div v-if="!pedido.enviado">
        <div v-if="cesta.items.length > 0">
          <div><h5>Productos</h5></div>
          <Table :items="productos_cesta_actualizados" :cabeceras="cabeceras_visibles"> <!-- productos_cesta_actualizados -->
            <template slot="cell(imagen2d)"    slot-scope="prop">
              <img :src="prop.item.imagen2d" class="img" data-label="Imagen">
            </template>
            <template slot="cell(descripcion)"  slot-scope="prop" data-label="Descripción">
              <span class="articulo_nombre">{{ prop.item.descripcion }}</span><br>
              <span class="articulo_codigo">Código</span> <span class="articulo_codigo_valor">{{ parseInt(prop.item.codigo_nacional) }}</span>
            </template>

            <template slot="cell(plazo)" slot-scope="prop" data-label="Plazo">
              <span class="articulo_codigo_valor" v-if="prop.item.plazo.texto==0"><img  class="loading-icon" src="@/assets/loading.gif" alt=""/></span>
              <span v-else class="articulo_codigo_valor">{{ prop.item.plazo.texto }}</span>
            </template>

            <template slot="cell(pvu)" slot-scope="prop" data-label="PVU">
              <span class="articulo_codigo_valor">
                {{ user.cooperativa.precio !== "Sin precio"?
                  ( user.cooperativa.precio == "Almacen"?
                          (prop.item.variantes[0]? parseFloat(prop.item.variantes[0].pvl).toFixed(2).replace(".", ",") : parseFloat(prop.item.pvl).toFixed(2).replace(".", ",") )
                          :
                          (prop.item.pvu? parseFloat(prop.item.pvu).toFixed(2).replace(".", ",") : parseFloat(prop.item.pvl).toFixed(2).replace(".", ",") )
                  )
                  : ''
                }} &euro;
              </span>

            </template>
            <template slot="cell(cantidad)" slot-scope="prop" data-label="Cantidad">
              <input-number v-if="!loading" class="cantidad" :disabled="loading" :value="prop.item.cantidad" @change="cambiarCantidad({item:prop.item, cantidad:$event})" :min="input_min" :max="input_max" size="2"/>
              <span v-else >{{ prop.item.cantidad }}</span>
            </template>
            <template slot="cell(borrar)" slot-scope="prop" data-label="Borrar">
               <span v-if="!loading" class="icon-trash-outline" style="font-size: 18px; color: rgb(0, 91, 187); cursor: pointer;"
                     @click="borrarItemCesta(prop.item)"></span>

               <img v-else class="loading-icon" src="@/assets/loading.gif" alt="Cargando" />

            </template>
          </Table>
        </div>
        <div v-else>No se encuentran productos en la cesta</div>
      </div>
      <div v-else-if="pedido.err_conn===1">
        <div class="d-flex justify-content-center">
          <div class="row">
            <div class="col-md-12 text-center"><span style="font-size:90px;color:#ea9529" class="icon-alert-triangle-outline"/></div>
            <div class="col-md-12 m-3 text-center">Ocurrió un error enviando el pedido. Error en la conexión</div>
            <div class="col-md-12 text-center">
              <!--a class="boton mr-2" size="sm" :href="pedido.cctr" target="_blank">Ver errores</a-->
              <b-button class="boton" @click="closeModal()" size="sm">Volver</b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="pedido.error && pedido.err_conn===0">
        <div class="d-flex justify-content-center">
          <div class="row">
            <div class="col-md-12 text-center"><span style="font-size:90px;color:#ea9529" class="icon-alert-triangle-outline"/></div>
            <div class="col-md-12 m-3 text-center">Ocurrió un error. {{ pedido.error_description }}</div>
            <div class="col-md-12 mb-3 text-center">
              {{ pedido.cctr }}
            </div>
            <div class="col-md-12 text-center">
              <b-button class="boton" @click="closeModal()" size="sm">Volver</b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-center">
          <div class="row">
            <div class="col-md-12 text-center"><span style="font-size:90px;" class="icon-done-all-outline"/></div>
            <div class="col-md-12 mt-2 mb-2 text-center">El pedido se ha enviado correctamente</div>
            <div v-if="pedido.artic_no_servidos.length>0" class="col-md-12 text-center m-3">
              No hay servicio para los siguientes artículos:<br>
              <span v-for="articulo in pedido.artic_no_servidos">{{ articulo }}<br></span>
            </div>
            <div v-if="pedido.artic_cambio_coop.length>0" class="col-md-12 text-center m-3">
              Hay cambios en la entrega de los siguientes artículos:<br>
              <span v-for="articulo in pedido.artic_cambio_coop">{{ articulo }}<br></span>
            </div>
            <div v-if="pedido.artic_cambio_plazo.length>0" class="col-md-12 text-center m-3">
              Se han producido cambios en los plazos de entrega que afectan a los siguientes artículos:<br>
              <span v-for="articulo in pedido.artic_cambio_plazo">{{ articulo }}<br></span>
              <br>
              Más información en los detalles del pedido.
            </div>
            <div class="col-md-12 text-center pl-2">
              <!--b-button class="boton mr-2" :href="pedido.cctr" size="sm" target="_blank">Detalles pedido</b-button-->
              <b-button v-if="pedido.pedido_realizado.pedido" class="boton mr-2" @click="mostrarModalPedido()" size="sm">Detalles pedido</b-button>
              <b-button v-else class="boton mr-2" size="sm" disabled>Cargando detalles...</b-button>
              <b-button class="boton" @click="closeModal()" size="sm">Volver</b-button>
            </div>
          </div>
        </div>
      </div>

    </template>
  </Modal>


  </div>
</template>

<script>
import Modal from "../../common/modal.vue";
import Table from  "../../common/table.vue";
import { mapState,mapActions,mapMutations, mapGetters } from 'vuex';
import cesta from "@/store/cesta";
import httpClient from "@/api/httpClient";

export default {
  name: "modal-cesta",
  mixins: [],
  props: [],
  components: {Modal, Table},
  setup() {
    const title = "Mi cesta";
    const btnlabel= "Enviar pedido";

    return {
      title,btnlabel

    };
  },
  data () {
    return {
      cabeceras: [
        {
          key: "imagen2d",
          label: "",
          class:"w-10"
        },
        {
          key: "descripcion",
          label: "Descripción",
          class:"w-25"
        },
        {
          key: "plazo",
          label: "Plazo",
          class:"w-15"
        },
        {
          key: "pvu",
          label: "PVU",
          class:"w-10"
        },
        {
          key: "cantidad",
          label: "Cantidad",
          class:"w-10"
        },
        {
          key: "borrar",
          label: "",
          class:"w-5"
        }
      ],
      input_min:1,
      input_max:20,
      opciones_pedido:[
        { text:'Seleccione una opción', value:'', selected:true},
        { text:'Siguiente reparto', value:'A'},
        { text:'24 horas', value:'B'},
        { text:'48 horas', value:'C'},
        { text:'Más de 48 horas', value:'D'}
      ],
      opcion_pedido_seleccionada:"",
      prioridades: [],
      existencias: {},
      modal_pedido:false,
    };
  },
  created() {
    if(cesta.state.cesta.items.length>0){
      httpClient.get(`prioridades`).then((response) => {
        this.prioridades = response.data.prioridades;
        this.crearTablaExistencias();
        this.getExistencias();
      });
    }
  },
  computed:{
    ...mapState(['user']),
    ...mapState('cesta',['cesta','pedido','loading']),
    opcionPorDefecto(){
      return "B";
    },
    opcionMaximaSeleccionada(){
      return this.opciones_pedido.filter(item => item.value === this.cesta.tipo_envio)[0].text;
    },
    productos_cesta_actualizados() {
      let productos_existencias = this.cesta.items.map((item) => {
        return {
          id: item.id,
          codigo_nacional: item.codigo_nacional,
          descripcion: item.descripcion,
          pvl: item.pvl,
          pvu: item.pvu,
          imagen2d: item.imagen2d,
          config_cooperativas: item.config_cooperativas,
          ...this.existencias[item.codigo_nacional],
        };
      });
      let arr_prods = [];
      //console.log(this.cesta.items);
      for (let i = 0; i < productos_existencias.length; i++) {
        const articulo = {
          id: this.cesta.items[i].id,
          codigo_nacional: this.cesta.items[i].codigo_nacional,
          descripcion: this.cesta.items[i].descripcion,
          pvl: this.cesta.items[i].pvl,
          pvu: this.cesta.items[i].pvu,
          imagen2d: this.cesta.items[i].imagen2d,
          config_cooperativas: this.cesta.items[i].config_cooperativas,
          coop_sirve: null,
          sirve: " - ",
          plazo: {
            id: "",
            texto: 0, //"Consultando plazo..."
          },
          variantes: [],
          prioridades: [],
          cantidad: this.cesta.items[i].cantidad,
        };
        let sirve = false;
        let pide_principal = false;
        for (let j = 0; j < this.prioridades.length; j++) {
          let existe =
              productos_existencias[i][this.prioridades[j].almacen.id];
          if (!sirve && existe) {
            articulo.sirve = `${this.prioridades[j].almacen.nombre} (${this.prioridades[j].almacen.cooperativa.nombre})`;
            articulo.coop_sirve = this.prioridades[j].almacen.cooperativa.id_unnefar;
            articulo.plazo = {
              id: this.prioridades[j].plazo_entrega,
              texto: `${this.prioridades[j].texto_respuesta_pedido}`,
            };
            sirve = true;
          }
          articulo.prioridades.push({
            almacen: this.prioridades[j].almacen.nombre,
            cooperativa: this.prioridades[j].almacen.cooperativa.nombre,
            existe: existe,
            prioridad: this.prioridades[j].prioridad,
            plazo: existe
                ? `${this.prioridades[j].texto_respuesta_pedido}`
                : `-`,
          });
        }
        arr_prods.push(articulo);
      }
      cesta.items = arr_prods;
      this.actualizaCesta(cesta.items);
      return cesta.items;
    },
    cabeceras_visibles(){
      if(this.user && (this.user.cooperativa.precio=="Sin precio")){
        return this.cabeceras.filter(item => item.key != "pvu");
      } else {
        return this.cabeceras;
      }
    }

  },
  methods: {
    ...mapMutations('cesta',['CAMBIAR_ITEM_CESTA','BORRAR_ITEM_CESTA','SELECCIONAR_OPCION_PEDIDO']),
    ...mapActions('cesta',['ENVIAR_PEDIDO','MOSTRAR_MODAL_CESTA','ACTUALIZA_CESTA']),
    closeModal(){
      this.$emit("cerrar-modal")
    },
    actualizaCesta(items) {
      this.ACTUALIZA_CESTA(items);
    },
    borrarItemCesta(item){
      if(confirm(`Está seguro de borrar el item ${item.descripcion} de la cesta?`)){
        this.BORRAR_ITEM_CESTA(item);
      }
    },
    cambiarCantidad({item,cantidad}){
      this.CAMBIAR_ITEM_CESTA({item:item,cantidad:cantidad});
    },
    seleccionarOpcion(e){
      this.SELECCIONAR_OPCION_PEDIDO(e.value);
    },
    enviarPedido(){
      this.ENVIAR_PEDIDO();
    },
    mostrarModalPedido(){
      this.$emit("abrir-modal-pedido");
    },
    crearTablaExistencias() {
      let existencias = {};
      for (let i = 0; i < this.cesta.items.length; i++) {
        existencias[this.cesta.items[i].codigo_nacional] = {
          descripcion: this.cesta.items[i].descripcion,
        };
        for (let j = 0; j < this.prioridades.length; j++) {
          existencias[this.cesta.items[i].codigo_nacional][
              this.prioridades[j].almacen.id
              ] = false;
        }
      }
      this.existencias = existencias;
    },
    getExistencias() {
      let productos = this.cesta.items.map((item) => item.id);

      let arr_peticiones = [];
      for (let i = 0; i < this.prioridades.length; i++) {
        arr_peticiones.push(
            httpClient.post(`existencias`, {
              ids_productos: productos,
              id_almacen: this.prioridades[i].almacen.id,
              id_prioridad: this.prioridades[i].id
            })
        );
      }
      Promise.all(arr_peticiones).then((resultados) => {
        for (let resultado_peticion in resultados) {
          this.asignarExistencias(resultados[resultado_peticion].data);
        }
      });
    },
    asignarExistencias(data) {
      let existencias_cooperativa = data.existencias;
      for (let existencia in existencias_cooperativa) {
        if (this.existencias.hasOwnProperty(existencia)) {
          this.existencias[existencia][data.id_almacen] =
              existencias_cooperativa[existencia];
        }
      }
    },


  }
};
</script>
