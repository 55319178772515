<template>
  <div class="multiple-select-custom-subtoptions">
    <div class="multiple-select-custom-subtoptions-input">
      <div class="multiple-select-check" v-for="(option, index) in suboptions">
        <InputBox type="text" :value="option.value" @change="change(option)" :label="option.text" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "multiple-select-inner-input",
    props: ["suboptions"],
    methods: {
      checked: function (option) {
        this.$emit("change", option);
      },
    },
  };
</script>
