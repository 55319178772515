<template>
  <div class="multiple-select-custom">
    <div class="multiple-select-custom-icon-text">
      <span :class="icon"></span>
      <p>{{ label }}</p>
    </div>
    <tooltip v-if="getNumberElementsSelected > 0" position="bottom" show-event="hover">
      <span class="circle-number">+{{ getNumberElementsSelected }}</span>
      <template #content>
        <span>{{ getFilterNameElementsSelected }}</span>
      </template>
    </tooltip>

    <span class="icon-chevron-down-outline" v-bind:class="{ open: collapse }" @click="collapse = !collapse"></span>

    <div class="multitple-select-custom-options" v-if="collapse">
      <div class="multiple-select-custom-option" :key="index" v-for="(option, index) in options">
        <div class="multiple-select-custom-text">
          <p>{{ option.text }}</p>
          <span class="icon-chevron-right-outline"></span>
        </div>

        <multiple-select-inner-normal v-if="option.type === 'select'" :suboptions="option.suboptions" @selected="selected(index, ...arguments)" />
        <multiple-select-inner-check v-if="option.type === 'check'" :suboptions="option.suboptions" @checked="checked(index, ...arguments)" />
        <multiple-select-inner-input v-if="option.type === 'input'" :suboptions="option.suboptions" @change="change(index, ...arguments)" />
        <multiple-select-inner-with-sub-options v-if="option.type === 'subcheck'" :suboptions="option.suboptions" />
      </div>
    </div>
  </div>
</template>

<script>
  import MultipleSelectInnerNormal from "./multipleSelectInner/MultipleSelectInnerNormal";
  import MultipleSelectInnerCheck from "./multipleSelectInner/MultipleSelectInnerCheck";
  import MultipleSelectInnerInput from "./multipleSelectInner/MultipleSelectInnerInput";
  import MultipleSelectInnerWithSubOptions from "./multipleSelectInner/MultipleSelectInnerWithSubOptions";

  export default {
    name: "multiple-select-custom",
    props: ["icon", "label", "options", "filterOpened"],
    components: {
      MultipleSelectInnerCheck,
      MultipleSelectInnerNormal,
      MultipleSelectInnerInput,
      MultipleSelectInnerWithSubOptions,
    },
    data() {
      return {
        collapse: false,
      };
    },
    watch: {
      collapse(newValue, oldValue) {
        if (newValue) {
          this.$emit("openFilter", this.label);
        }
      },
      filterOpened(newValue, oldValue) {
        if (this.filterOpened != this.label && this.collapse) {
          this.collapse = false;
        }
      },
    },
    computed: {
      getNumberElementsSelected: function () {
        let number = 0;
        if (this.options) {
          this.options.forEach(item => {
            item.suboptions.forEach(subitem => {
              if (subitem.selected) number++;
            });
          });
        }

        return number;
      },
      getFilterNameElementsSelected: function () {
        let text = [];
        if (this.options) {
          for (let i = 0; i < this.options.length; i++) {
            let item = this.options[i];
            let innerText = [];
            for (let j = 0; j < item.suboptions.length; j++) {
              let subitem = item.suboptions[j];
              if (subitem.selected) {
                innerText.push(subitem.text);
              }
            }
            if (innerText.length > 0) {
              text.push(item.text + ": " + innerText.join(" / "));
            }
          }
        }
        return text.join("<br />");
      },
    },
    methods: {
      selected: function (index, value) {
        this.$emit("selected", { item: value, index: index });
      },
      checked: function (index, value) {
        this.$emit("checked", { item: value, index: index });
      },
    },
  };
</script>

<style lang="scss">
  .multiple-select-custom {
    position: relative;
    height: 45px;
    padding: 0 15px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .circle-number {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: var(--fc-brand-01-medium);
      color: var(--fc-brand-01);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 11px;
      line-height: 55px;
      text-align: center;
      margin-right: 10px;
    }
    .multiple-select-custom-icon-text {
      display: flex;
      align-items: center;
      p {
        margin: 0 10px 0 5px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: var(--fc-brand-03);
      }
      span {
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        line-height: 15px;
        color: var(--fc-brand-03);
      }
    }
    .icon-chevron-down-outline {
      cursor: pointer;
      font-size: 22px;
      line-height: 22px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      &.open {
        transform: rotate(180deg);
      }
    }

    .multitple-select-custom-options {
      z-index: 999;
      position: absolute;
      top: 50px;
      left: 0;
      width: 180px;
      padding: 8px 0;
      background-color: #fff;
      border: 1px solid #e2e2e2;
      border-radius: 6px;
      box-shadow: 0px 3px 10px rgba(216, 213, 213, 0.25);
      .multiple-select-custom-option {
        cursor: pointer;
        width: 100%;
        padding: 0 8px;
        .multiple-select-custom-text {
          width: 100%;
          padding: 9px 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 6px;
          -webkit-transition: 0.3s;
          transition: 0.3s;
          p {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            margin: 0;
            color: var(--fc-brand-02);
          }
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            line-height: 24px;
            color: var(--fc-brand-03);
          }
        }
        &:hover {
          .multiple-select-custom-text {
            background-color: var(--fc-brand-01);
            * {
              color: #fff;
            }
          }
          .multiple-select-custom-subtoptions {
            display: block;
          }
        }
      }
    }
    .multiple-select-custom-subtoptions {
      display: none;
      z-index: 999;
      position: absolute;
      min-width: 150px;
      width: auto;
      top: 0px;
      left: 180px;
      padding: 15px;
      background: #fafafa;
      border: 1px solid #e2e2e2;
      border-radius: 6px;
      box-shadow: 0px 3px 10px rgba(216, 213, 213, 0.25);
    }
  }
</style>
