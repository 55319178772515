<template>
  <div id="bars-with-average">
    <b-row class="bars-with-average-container">
      <b-col :cols="4" class="bars-with-average-content d-flex flex-column align-items-center" v-for="d in data" :key="d.label">
        <tooltip position="top" show-event="hover" :key="d.label">
          <div class="d-flex justify-content-center mb-3">
            <vertical-bullet-naf :width="12" :color="colorLab" :height="230" :percent="d.percentLab * 100" class="mr-3" />
            <vertical-bullet-naf :width="12" :color="colorNet" :height="230" :percent="d.percentRed * 100" />
          </div>
          <template #content>
            <div class="tooltip-content">
              <table style="width: 200px">
                <tr>
                  <td>{{ $t("charts.myLab") }}</td>
                  <td>{{ $n(d.lab, d.format) }}</td>
                </tr>
                <tr>
                  <td>{{ $t("charts.myNet") }}</td>
                  <td>{{ $n(d.red, d.format) }}</td>
                </tr>
              </table>
            </div>
          </template>
        </tooltip>
        <p class="mb-0">{{ d.label }}</p>
      </b-col>

      <div v-if="average != null" class="average-line" v-bind:style="{ top: average + '%' }"></div>
    </b-row>

    <div class="mt-5 d-flex justify-content-center w-100">
      <div class="header-right-slot-circle mr-3" v-if="average != null">
        <span style="background: #f9b119"></span>
        {{ $t("charts.pharmaAverage") }}
      </div>
      <div class="header-right-slot-circle mr-3">
        <span :style="'background: ' + colorLab"></span>
        {{ $t("charts.myLab") }}
      </div>
      <div class="header-right-slot-circle">
        <span :style="'background: ' + colorNet"></span>
        {{ $t("charts.myNet") }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "bars-with-average",
    props: ["data", "average"],
    data() {
      return {
        colorNet: window.colors.brand03,
        colorLab: window.colors.brand01,
      };
    },
    created() {
      console.log("bars-with-average data", this.data);
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/scss/_header_circle_right.scss";

  #bars-with-average {
    .bars-with-average-container {
      position: relative;
      .average-line {
        z-index: 2;
        position: absolute;
        width: 70%;
        left: 15%;
        height: 1px;
        background-color: #f9b119;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:before,
        &:after {
          z-index: 2;
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 18px;
          background-color: #f9b119;
        }
      }
      .bars-with-average-content {
        p {
          text-align: center;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #b1b1b1;
        }
      }
    }
  }
</style>
