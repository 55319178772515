<template>
   <div class="p-4">
        <pedido-detalle v-if="pedido" :pedido="pedido" tipo="individual"></pedido-detalle>
        <div v-else>
            <div class="centrado d-flex justify-content-center align-items-center">
                <div>
                    <div class="d-flex justify-content-center">
                      <div class="row ">
                          <div class="col-md-12  text-center"><span style="font-size:90px" class="icon-eye-off-outline"/></div>
                          <div class="col-md-12 m-3 text-center">Cargando datos del pedido...</div> <!-- Parece que no tienes acceso a este contenido -->
                      </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import httpClient from '@/api/httpClient';
import PedidoDetalle from '@/components/integrado/PedidoDetalle.vue';

export default {
    name: "Cctr",
    components:{
        PedidoDetalle
    },
    data:() =>{
        return {
            pedido:null
        }
    },
    created(){
        let codigo = this.$route.params.codigo;
        httpClient.get(`/pedido-detalle/${codigo}`).then(
          response => {
            this.pedido=response.data.pedido
          }
        );
    }
};
</script>

<style lang="scss" scoped>

</style>
