<template>
  <div id="login-page" class="d-flex">
    <div class="centrado d-flex justify-content-center align-items-center">
      <div>
        <div class="d-flex justify-content-center">
          <div class="row ">
              <div class="col-md-12  text-center"><span style="font-size:90px" class="icon-eye-off-outline"/></div>
              <div class="col-md-12 m-3 text-center">Parece que no tienes acceso a este contenido</div>
              <div class="col-md-12  text-center"><b-button class="boton" @click="goLoginForm()"  size="sm"  :style="themeColors">Volver a acceder</b-button></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { CarouselSingle } from "frontendcommon";
  import auth from "@/api/auth";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "Login",
    extends: auth,
    components: { CarouselSingle },
    data() {
      return {
        step: 0,
        showResetPassword: false,
        credentials: {
          username: "",
          password: "",
          coop: "",
          ctr: "",
        },
        form: {
          username: {
            hasError: false,
            errors: [],
          },
          password: {
            hasError: false,
            errors: [],
          },
        },
        slides: [
          {
            src: "./assets/step-1.svg",
            title: "Obtén una visión amplia y profunda",
            content: "Panel de farmacias con información de sell out y reporte diario",
          },
          {
            src: "./assets/step-1.svg",
            title: "Diferénciate de tus competidores",
            content: "Analiza en detalle todos los indicadores e identifica los kpis que te aporten una ventaja competitiva o puedas mejorar",
          },
          {
            src: "./assets/step-1.svg",
            title: "Diferénciate en tus decisiones",
            content: "Potencia el crecimiento de tu mercado y marcas",
          },
        ],
      };
    },
    created() {
      this.credentials.username = this.getUsername();
    },
    methods: {
      goLoginForm(){
        window.location=`https://surtidorcruzfarma.com/farmacias`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  #login-page {
    background: #ffffff;
    height: 1000px;
    .centrado{
      margin: 0 auto;
    }
  }
</style>
