<template>
  <div class="scatter-dist-pvp-container" style="position: relative" width="100%"></div>
</template>

<script>
  import colors from "@/mixins/colors";

  export default {
    name: "scatter-dist-pvp",
    props: ["data", "formatNumber"],
    mixins: [colors],
    methods: {
      onResize(event) {
        var vm = this;
        setTimeout(function () {
          vm.crearSVG();
        }, 200);
      },
      crearSVG() {
        var vm = this;

        var tiendasonline = this.data.tiendasonline,
          precioscongastos = 1,
          activarPVPActual = false,
          formatNumber = function (a, b) {
            return vm.$n(a, b);
          };

        this.$d3.select(this.$el).selectAll("*").remove();

        var width = this.$el.getBoundingClientRect().width,
          position = { left: this.$el.getBoundingClientRect().left, top: this.$el.getBoundingClientRect().top - 250 },
          height = 250,
          axiosLeftWith = 40,
          axiosBottomHeight = 20,
          legendSpacing = 4;

        if (width <= 0) return;

        // set the ranges
        var x = this.$d3.scaleLinear().range([0, width - axiosLeftWith]);
        var y = this.$d3.scaleLinear().range([height - axiosBottomHeight, 0]);

        var d3 = this.$d3;

        console.log("THIS DATAAAA", formatNumber(2, "currency"));

        var calcDomain = [this.data.datosRedAvg[0]];

        for (var i = 0; i < this.data.datosOnline.length; i++) {
          calcDomain.push([this.data.datosOnline[i]["precio"] + this.data.datosOnline[i]["gastosenvio"], 0]);
        }

        var svg = this.$d3.select(this.$el).append("svg").attr("width", width).attr("height", height).append("g");

        // Scale the range of the data
        console.log("y(d[1]) calcDomain", calcDomain);
        let minX = this.$d3.min(calcDomain, function (d) {
          return d[0];
        });
        let maxX = this.$d3.max(calcDomain, function (d) {
          return d[0];
        });
        let maxY = this.$d3.max(calcDomain, function (d) {
          return d[1];
        });
        x.domain([minX * 0.8, maxX * 1.2]);
        y.domain([0, maxY * 1.2]);

        var tooltip = this.$d3.select(this.$el).append("div").attr("class", "scatter-dist-pvp-tooltip").style("position", "absolute").style("visibility", "hidden");

        svg
          .selectAll("dot")
          .data(this.data.datosRedAvg)
          .enter()
          .append("circle")
          .attr("r", 12)
          .style("fill", window.colors.brand03)
          .style("stroke-width", "1")
          .style("stroke", window.colors.brand03)
          .attr("cx", function (d) {
            return x(activarPVPActual ? d[2] : d[0]);
          })
          .attr("cy", function (d) {
            return y(d[1]);
          })
          .on("mouseenter", function (d) {
            tooltip
              .style("left", position.left + y(d[1]) + "px")
              .style("top", y(d[1]) + "px")
              .style("left", x(activarPVPActual ? d[2] : d[0]) + "px")
              .style("visibility", "visible")
              .html("PVP medio: " + formatNumber(d[0], "currency") + "<br/>PVP actual : " + formatNumber(d[2], "currency") + "<br/>" + "Ventas: " + formatNumber(d[1], "unidades") + " uds.");
          })
          .on("mouseout", function (d) {
            tooltip.style("visibility", "hidden");
          });

        for (var i = 0; i < this.data.datosOnline.length; i++) {
          this.data.datosOnline[i]["popup"] = this.data.datosOnline[i]["tienda_online"] + "<br/>" + formatNumber(this.data.datosOnline[i]["precio"], "currency") + " + " + formatNumber(this.data.datosOnline[i]["gastosenvio"], "currency") + " envío";
        }

        svg
          .selectAll("dot")
          .data(this.data.datosOnline)
          .enter()
          .append("line")
          .attr("x1", function (d) {
            return x(d["precio"] + d["gastosenvio"]);
          })
          .attr("y1", 0)
          .attr("x2", function (d) {
            return x(d["precio"] + d["gastosenvio"]);
          })
          .attr("y2", height - axiosBottomHeight)
          .attr("stroke-width", 5)
          .attr("stroke", "#7DAADA")
          .on("mouseenter", function (d) {
            tooltip
              .style("top", height / 2 + "px")
              .style("left", x(d["precio"] + d["gastosenvio"]) + "px")
              .style("visibility", "visible")
              .html(d["popup"]);
          })
          .on("mouseout", function (d) {
            tooltip.style("visibility", "hidden");
          });

        // Add the X Axis
        const axisBottomG = svg
          .append("g")
          .attr("class", "axisBottom")
          .attr("transform", "translate(0," + (height - axiosBottomHeight) + ")")
          .call(this.$d3.axisBottom(x).tickFormat(d => formatNumber(d, "currency")));
        axisBottomG.classed("axisGrey", true);

        axisBottomG.selectAll(".tick text").attr("fill", "#b1b1b1");

        // text label for the x axis

        const axisLeftG = svg
          .append("g")
          .attr("class", "axisLeft")
          .attr("transform", "translate(" + axiosLeftWith + "," + axiosBottomHeight + ")")
          .call(this.$d3.axisLeft(y).tickFormat(d => formatNumber(d, "unidades")));
        axisLeftG.classed("axisGrey", true);
        axisLeftG.selectAll(".tick text").attr("fill", "#b1b1b1");

        // Add the Y Axis
        // svg.append("g").call(this.$d3.axisLeft(y));
      },
    },
    mounted: function () {
      console.log("data", this.data);
      this.crearSVG();
    },
  };
</script>

<style lang="scss">
  .scatter-dist-pvp-container {
    .axisBottom,
    .axisLeft {
      .domain {
        display: none;
      }
      .tick {
        line {
          display: none;
        }
        text {
          //styleName: H8;
          font-family: Atlas Grotesk;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          text-align: center;
          color: #b1b1b1;
        }
      }
    }

    .scatter-dist-pvp-tooltip {
      background: var(--fc-brand-03, #3d3f49);
      color: #fff;
      padding: 12px 18px;
      border-radius: 6px;
      z-index: 2000;
      text-align: left;
    }
  }
</style>
