<template>
  <div class="bar-dist-pvp-container" width="100%"></div>
</template>

<script>
  import colors from "@/mixins/colors";

  export default {
    name: "bar-dist-pvp",
    props: ["data", "formatNumber", "dataRed", "pvpoptimo"],
    mixins: [colors],
    methods: {
      onResize(event) {
        var vm = this;
        setTimeout(function () {
          vm.crearSVG();
        }, 200);
      },
      crearSVG() {
        var vm = this;

        var activarPVPActual = false,
          formatNumber = function (a, b) {
            return vm.$n(a, b);
          };

        this.$d3.select(this.$el).selectAll("*").remove();

        var width = this.$el.getBoundingClientRect().width,
          parentElement = this.$el,
          position = { left: this.$el.getBoundingClientRect().left, top: this.$el.getBoundingClientRect().top - 250 },
          height = 250,
          data = this.data,
          dataRed = this.dataRed,
          pvp_optimo = this.pvpoptimo,
          d3 = this.$d3,
          axiosLeftWith = 40,
          axiosBottomHeight = 20;
        if (width <= 0) return;

        // set the ranges
        var x = d3
          .scaleBand()
          .rangeRound([0, width])
          .paddingInner(0.1)
          .paddingOuter(0.8)
          .domain(
            data.map(function (d) {
              return d[0];
            })
          );

        var maxY = d3.max(data, function (d) {
          return d[1];
        });
        var y = d3
          .scaleLinear()
          .range([height, 0])
          .domain([0, maxY * 1.2]);

        var d3 = this.$d3;

        var azulClaro = d3.rgb("#E2E2E2");
        var azulOscuro = d3.rgb("#E2E2E2").darker();

        var svg = this.$d3.select(this.$el).append("svg").attr("width", width).attr("height", height).append("g");

        var numBars = data.length;
        console.log("bardist", numBars);

        var redBand = 0;
        if (dataRed[0][1] > 0) {
          for (let valor of data) {
            redBand = valor;
            if ((!activarPVPActual && valor[0] >= dataRed[0][0]) || (activarPVPActual && valor[0] >= dataRed[0][2])) {
              break;
            }
          }
          svg
            .append("rect")
            .attr("x", x(redBand[0]) + (numBars > 1 ? -4 : x.bandwidth() / 2 - 4))
            .attr("y", 0)
            .attr("height", height - axiosBottomHeight)
            .attr("width", 4)
            .attr("fill", window.colors.brand03);
        }

        if (this.pvpoptimo > 0) {
          var redBandPVPOptimo = 0;
          for (let valor of data) {
            redBandPVPOptimo = valor;
            if (valor[0] >= this.pvpoptimo) {
              break;
            }
          }
          svg
            .append("rect")
            .attr("x", x(redBandPVPOptimo[0]) + (numBars > 1 ? (redBandPVPOptimo[0] == redBand[0] ? -8 : -2) : x.bandwidth() / 2 - (redBandPVPOptimo[0] == redBand[0] ? 8 : 2)))
            .attr("y", 0)
            .attr("height", height - axiosBottomHeight)
            .attr("width", 4)
            .attr("fill", "#7DAADA");
        }

        svg
          .selectAll(".bar")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", "bar")
          .attr("x", function (d) {
            console.log("bardist", d);
            return x(d[0]) + (numBars > 1 ? x.bandwidth() / 2 : 0);
          })
          .attr("width", x.bandwidth())
          .attr("y", function (d) {
            return y(d[1]);
          })
          .attr("height", function (d) {
            return height - axiosBottomHeight - y(d[1]);
          })
          .attr("fill", function (d) {
            return d[2] == "S" ? azulOscuro : azulClaro;
          });

        var tooltip = this.$d3.select(this.$el).append("div").attr("class", "bar-dist-pvp-tooltip").style("position", "absolute").style("visibility", "hidden");

        // Add the X Axis
        const axisBottomG = svg
          .append("g")
          .attr("class", "axisBottom")
          .attr("transform", "translate(0," + (height - axiosBottomHeight) + ")")
          .call(this.$d3.axisBottom(x).tickFormat(d => formatNumber(d, "currency")));
        axisBottomG.classed("axisGrey", true);

        axisBottomG.selectAll(".tick text").attr("fill", "#b1b1b1");

        // text label for the x axis

        const axisLeftG = svg
          .append("g")
          .attr("class", "axisLeft")
          .attr("transform", "translate(" + axiosLeftWith + "," + axiosBottomHeight + ")")
          .call(this.$d3.axisLeft(y).tickFormat(d => formatNumber(d, "unidades")));
        axisLeftG.classed("axisGrey", true);
        axisLeftG.selectAll(".tick text").attr("fill", "#b1b1b1");
      },
    },
    mounted: function () {
      console.log("bardist data", this.data);
      console.log("bardist dataRed", this.dataRed);
      this.crearSVG();
    },
  };
</script>

<style lang="scss">
  .bar-dist-pvp-container {
    .axisBottom,
    .axisLeft {
      .domain {
        display: none;
      }
      .tick {
        line {
          display: none;
        }
        text {
          //styleName: H8;
          font-family: Atlas Grotesk;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          text-align: center;
          color: #b1b1b1;
        }
      }
    }

    .bar-dist-pvp-tooltip {
      background: var(--fc-brand-03, #3d3f49);
      color: #fff;
      padding: 12px 18px;
      border-radius: 6px;
      z-index: 2000;
      text-align: left;
    }
  }
</style>
