import httpClient from './httpClient';

const END_POINT = '/indicadores/';

export default {
    methods: {
        getIndicadoresVentasApi: function (resolve, options) {
            console.log("getIndicadoresVentasApi", options)
            httpClient.post(END_POINT + 'indicadoresVentas',options)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getIndicadoresTicketMedioApi: function (resolve,tipo_venta) {
            httpClient.post(END_POINT + 'indicadoresTicketMedio',{tipo_venta:tipo_venta})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getLaboratorios: function (resolve) {
            httpClient.post( '/buscador/laboratorios')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },  
        getComunidades: function (resolve) {
            httpClient.post( '/maestros/comunidades')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },  
        getProvincias: function (resolve) {
            httpClient.post( '/maestros/provincias')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },          
        getIndicadoresStock: function (resolve) {
            httpClient.post(END_POINT + 'indicadoresStock')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },






        //NO SE USAN
        getIndicadoresMixVentasApi: function (resolve) {
            httpClient.post(END_POINT + 'mixdeventas')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getTopVentasLibresApi: function (resolve, option) {
            httpClient.post(END_POINT + 'topVentasLibres', {
                orden_red_far: option
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
}