import httpClient from "@/api/httpClient";
import Cesta from "./models/cesta";

export const KEY_STORE = 'cesta-integrado';
export const STORE_APP = 'store-app-integrado';
export const cesta = {
    namespaced: true,
    state: {
        modal_cesta: {
            value: false,
            header: '',
            content: '',
            footer: ''
        },
        cesta: {
            tipo_envio: "",
            items: [],
            id_socio: []
        },
        pedido: {
            error: false,
            error_description: "",
            enviado: false,
            data: null,
            err_conn: 0,
            artic_cambio_plazo: "",
            artic_no_servidos: "",
            artic_cambio_coop: "",
            cctr: "",
            pedido_realizado: {}
        },
        loading: false
    },
    getters: {},
    mutations: {
        SET_MODAL_CESTA(state, modal_config) {
            state.modal_cesta = modal_config
        },
        SET_MOSTRAR_MODAL_CESTA(state, show) {
            state.modal_cesta.value = show
        },
        COMPROBAR_CESTA(state, cesta){
            if(!state.cesta.items[0] && cesta){
                let arr_cesta = Cesta.get();
                if(arr_cesta.items.length>0 && arr_cesta.id_socio === JSON.parse(localStorage.getItem(STORE_APP)).user.id){
                    state.cesta = arr_cesta;
                }
                if(state.cesta.items){
                    state.cesta.items.sort((a, b) => a.plazo.id.localeCompare(b.plazo.id));
                    state.cesta.tipo_envio=state.cesta.items[state.cesta.items.length-1].plazo.id;
                    //state.cesta.tipo_envio = state.cesta.items[0].plazo.id;
                }
                Cesta.save(state.cesta);
            }
        },
        ACTUALIZAR_CESTA(state, cesta_items){
            state.cesta.items = cesta_items;
            if(state.cesta.items){
                state.cesta.items.sort((a, b) => a.plazo.id.localeCompare(b.plazo.id));
                state.cesta.tipo_envio=state.cesta.items[state.cesta.items.length-1].plazo.id;
            }
            Cesta.save(state.cesta);
        },
        ADD_ITEM_CESTA(state,item){
            state.pedido.enviado=false;
            state.cesta.tipo_envio="";
            state.cesta.id_socio = JSON.parse(localStorage.getItem(STORE_APP)).user.id;
            let pos = state.cesta.items.findIndex(p => p.codigo_nacional == item.codigo_nacional)
            if(pos > -1){
              state.cesta.items.map(p => p.codigo_nacional == item.codigo_nacional ? p.cantidad++ : p.cantidad )
            }else{
              state.cesta.items.push(item);
            }
            state.cesta.items.sort((a, b) => a.plazo.id.localeCompare(b.plazo.id));
            state.cesta.tipo_envio=state.cesta.items[state.cesta.items.length-1].plazo.id;
            //RINO-> state.cesta.tipo_envio=_.get(_.max(state.cesta.items,o => o.plazo.id),'plazo.id');
            //Cookie para mantener la cesta
            Cesta.save(state.cesta);
        },
        CAMBIAR_ITEM_CESTA(state,{item,cantidad}){
            state.cesta.items=state.cesta.items.map(p => {
              if(p.codigo_nacional == item.codigo_nacional){
                  p.cantidad=cantidad
              }
              return p;
            });
            Cesta.save(state.cesta);
        },
        BORRAR_CESTA(state,data){
            state.cesta.tipo_envio="";
            state.cesta.items=[];
            state.pedido.enviado=true;
            state.pedido.data=data;
            Cesta.save(state.cesta);
        },
        BORRAR_ITEM_CESTA(state,item){
            state.cesta.items=state.cesta.items.filter(p => p.codigo_nacional !== item.codigo_nacional);
            state.cesta.items.sort((a, b) => a.plazo.id.localeCompare(b.plazo.id));
            state.cesta.tipo_envio=state.cesta.items?? state.cesta.items[state.cesta.items.length-1].plazo.id?? "";
            //RINO-> state.cesta.tipo_envio=_.get(_.max(state.cesta.items,o => o.plazo.id),'plazo.id');
            Cesta.save(state.cesta);
            if(state.cesta.items[0]){
                state.cesta.tipo_envio = state.cesta.items[0].plazo.id;
            }
        },
        SELECCIONAR_OPCION_PEDIDO(state,opcion){
            state.cesta.tipo_envio=opcion
        },
        SET_LOADING(state,valor){
            state.pedido.error=false;
            state.pedido.err_conn=0;
            state.loading=valor;
        },
        SET_CAMBIO_PLAZOS(state, params){
            if(params.articulos.length > 0){
                state.pedido.artic_cambio_plazo=params.articulos;
            }else {
                state.pedido.artic_cambio_plazo='';
            }
        },
        SET_CAMBIO_COOP(state, params){
            if(params.articulos.length>0){
                state.pedido.artic_cambio_coop=params.articulos;
            } else {
                state.pedido.artic_cambio_coop='';
            }
        },
        SET_NO_SERVIDOS(state,params){
            if(params.articulos.length > 0){
                state.pedido.artic_no_servidos=params.articulos;
            } else {
                state.pedido.artic_no_servidos='';
            }
        },
        PEDIDO_ENVIADO(state, params){
            console.log("GUARDAR PEDIDO", params);
            state.pedido.pedido_realizado=params;
        },
        SET_PEDIDO_ERROR(state,params){
            console.log("error 1",params);
            state.pedido.enviado=true;
            state.pedido.error=true;
            state.pedido.error_description=params.error_description;
            state.loading=false;
            state.pedido.cctr=params.cctr;
            state.pedido.err_conn=params.err_conn;
        }
    },
    actions: {
        COMPROBAR_ESTADO_CESTA({commit,state}, estado){
            commit('COMPROBAR_CESTA', estado);
        },
        ACTUALIZA_CESTA({commit, state}, cesta_items){
            commit('ACTUALIZAR_CESTA', cesta_items);
        },
        MOSTRAR_MODAL_CESTA({commit,state},show){
            //commit('ACTUALIZA_CESTA');
            commit('SET_MOSTRAR_MODAL_CESTA',show)
        },
        ENVIAR_PEDIDO({commit,state},params){
            commit('SET_LOADING',true);
            httpClient.post("realizar-pedido",state.cesta).then(
                response => {
                    let arr_fed_respuesta = response.data.fedicom.fedicom_respuesta.split("\r\n");
                    let lineas_envio = response.data.fedicom.lineas;
                    let mensaje = '';
                    if(response.data.fedicom.fedicom_respuesta && (arr_fed_respuesta[1].charAt(30) == '1' || arr_fed_respuesta[1].substr(0,4) == '9999')){
                        if(arr_fed_respuesta[1].charAt(30) == '1'){
                            mensaje = arr_fed_respuesta[2].substr(44);
                        } else if(arr_fed_respuesta[1].substr(0,4) == '9999'){
                            mensaje = arr_fed_respuesta[1].substr(4);
                        }
                        commit('SET_PEDIDO_ERROR',{error_description: "ERROR EN EL ENVÍO", err_conn: 0, cctr: mensaje});
                    } else {
                        //timeout para esperar a que se escriban las lineas en ni_pedidos_lineas
                        setTimeout( function() {
                            state.pedido.cctr = arr_fed_respuesta[2].substr(4, 28);
                            httpClient.post("comprueba-plazos-ped", {id_pedido: response.data.fedicom.pedido.id})
                                .then(
                                    response2 => {
                                        let articulos_plazos = [];
                                        let art_no_servidos = [];
                                        let art_cambio_coop = [];
                                        let lineas_respuesta = response2.data.pedido_lineas.lineas;
                                        for (let a in lineas_respuesta) {
                                            let linea = lineas_respuesta[a];
                                            for (let b in lineas_envio) {
                                                let linea_envio = lineas_envio[b];
                                                if (linea_envio.codigo_nacional === linea.codigo_producto) {
                                                    if (parseInt(linea.cantidad_servida) > 0) {
                                                        if (linea_envio.plazo.id !== linea.plazo) {
                                                            articulos_plazos.push(linea.codigo_producto + " - " + linea.nombre_producto);
                                                        }
                                                        if (linea_envio.coop_sirve !== linea.coop_sirve) {
                                                            art_cambio_coop.push(linea.nombre_producto + "(" + linea.codigo_producto + ")" + (linea.texto_respuesta && linea.texto_respuesta.length > 0 ? " - " + linea.texto_respuesta : ''));
                                                        }
                                                    }
                                                    if (parseInt(linea.cantidad_servida) === 0) {
                                                        art_no_servidos.push(linea.codigo_producto + " - " + linea.nombre_producto);
                                                    }
                                                    break;
                                                }
                                            }
                                        }
                                        commit('SET_CAMBIO_PLAZOS', {articulos: articulos_plazos});
                                        commit('SET_CAMBIO_COOP', {articulos: art_cambio_coop});
                                        commit('SET_NO_SERVIDOS', {articulos: art_no_servidos});
                                        commit('PEDIDO_ENVIADO', {pedido: response2.data.pedido_lineas});
                                    }
                                ).catch(error => {
                                console.log("error plazos", error);
                            })
                            commit('SET_LOADING', false);
                        }, 3000)
                    }
                    commit('BORRAR_CESTA',response.data.pedido);
                }
            ).catch(error => {
                console.log("error", error);
                commit('SET_PEDIDO_ERROR',{error_description: error.message, err_conn: 1});
                commit('BORRAR_CESTA');
            })
        }
    },
};

export default cesta;
