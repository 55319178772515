<template>
  <div class="modal-box">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">
            {{ title }}
          </div>
          <button
            type="button"
            @click="close"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="icon-closesimple"></span>
          </button>
        </div>

        <div class="modal-body">
          <slot name="content"> Modal content</slot>
        </div>

        <div v-if="btnFunction" class="modal-footer footer-task-modal">
          <div class="buttons">
            <button class="btn btn-primary" @click="btnFunction">{{ btnlabel }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$emit("close-modal");
    }
  },
  props: ["title", "btnlabel", "btnFunction"],
};
</script>
