<template>
  <div id="search-view">
    <div class="search-view-fixed">
      <div class="search-view-header">
          <div class="search-view-container">
            <div class="row   search-view-container_header align-items-center">
             <div class="col-12 col-md-8 ">
                  <h2>{{ $t("dashboard.historico-pedidos.title") }}</h2>
              </div>
             <div class="col-12 col-md-4   pb-5 pb-md-0  text-right">
                <b-button @click="MOSTRAR_MODAL_CESTA(true)" icon="icon-shopping-cart-outline" iconPosition="left" style="background-color:#00478b">Mi cesta
                <span style="
                margin-left: 10px;
                color: #005BBB;
                height: 19px;
                width: 19px;
                padding: 3px;
                background-color: #ffff;
                border-radius: 50%;
                display: inline-block;
                ">{{ cesta.items.length }}</span></b-button>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div>

    </div>
    <div class="search-view-results">
      <div class="search-view-container mb-3">
          <div v-if="loading" class="d-flex justify-content-center">
            <img class="loading-icon" src="@/assets/loading.gif" alt="" />
          </div>
          <Table v-else="loading"  :cabeceras="cabeceras" :items="pedidos" sort-by="fecha">
             <template slot="cell(num_articulos)" slot-scope="prop">
                {{ prop.item.lineas.length }}
             </template>
             <template slot="cell(fecha)" slot-scope="prop">
                {{ prop.item.fecha | format_fecha }}
             </template>
             <template slot="cell(edit)" slot-scope="prop">
                <span class="icon-eye-outline" @click="mostrarModalPedido(prop.item)" style="cursor: pointer;font-size:18px;color:#005BBB"/>
             </template>
          </Table>
      </div>
    </div>
    <modal-cesta v-if="modal_cesta.value" @cerrar-modal="MOSTRAR_MODAL_CESTA(false)"/>
    <modal-pedido v-if="modal_pedido.mostrar" :pedido="modal_pedido.pedido_seleccionado" @cerrar-modal="cerrarModalPedido()"/>
  </div>
</template>

  <script>
    import pedidos from "@/api/pedidos";
    import { mapState,mapMutations, mapActions } from "vuex";
    import ModalCesta from "@/components/integrado/modal/ModalCesta.vue";
    import ModalPedido from "@/components/integrado/modal/ModalPedido.vue";
    import httpClient from "@/api/httpClient";
    import Table from "@/components/common/table";

    export default {
      name: "historico-pedidos",
      extends: pedidos,
      components: {
        ModalCesta,ModalPedido, Table
      },
      created() {
         this.loading=true;
         httpClient.get("/pedidos").then(
          response => {
            this.pedidos=response.data.pedidos;
            this.loading=false;
          }
        );
      },
      data() {
        return {
          items: [
            { urlName: "/", text: "Integrado" },
            { urlName: "/historico-pedidos", text: "Histórico pedidos" },
          ],
          cabeceras:[
              {
                  key:"num_pedido_origen",
                  label:"ID",
                  width:"10px",
                  sortable:"true"
              },
              {
                  key:"fecha",
                  label:"Fecha",
                  width:"300px",
                  sortable:"true"
              },
              {
                  key:"origen",
                  label:"Origen",
                  width:"100px"
              },
              {
                  key:"num_articulos",
                  label:"Num. Artículos",
                  width:"100px"
              },
              {
                  key:"edit",
                  label:"",
                  width:"100px"
              }
           ],
          pedidos:[],
          modal_pedido:{
            pedido_seleccionado:{},
            mostrar:false
          },
          loading:false
        };
      },
      computed:{
        ...mapState('cesta',['cesta','modal_cesta'])
      },
      methods: {
        ...mapActions('cesta',['MOSTRAR_MODAL_CESTA']),
        mostrarModalPedido(pedido){
            this.modal_pedido.pedido_seleccionado=pedido;
            this.modal_pedido.mostrar=true;
        },
        cerrarModalPedido(){
          this.modal_pedido.pedido_seleccionado={};
          this.modal_pedido.mostrar=false;
        }
      },
    };
  </script>

 <style lang="scss" scoped>
  #search-view {
    .search-view-fixed {
      position:relative;
      width: 100%;
      z-index: 6000;
      background-color: #f7f8fc;
    }
    .search-view-header {
      background-color: #fff;
    }
    .search-view-results {
     // margin-top: 210px;
    }

    .search-view-filters {
      margin-top: -30px;
    }
    .search-view-container {
      max-width: 1300px;
      margin: 0 auto;
      padding: 0 30px;

    }
    .search-view-container_header {

      min-height: 180px;
    }


    .search-view-paginator {
      width:100%;
      text-align: center;
      border:1px #000000
    }

    .search-background {
      position: relative;
      .search-select-input {
        width: 100%;
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        box-shadow: 0px 1px 4px rgba(188, 186, 186, 0.25);
        .input-custom,
        .select-custom {
          box-shadow: none;
          background: none;
        }
        .input-custom {
          position: relative;
          width: calc(100%);
          flex-basis: calc(100%);
        }
      }
    }

    #search-view-btn-load-more {
      text-align: center;
      margin: 0 auto;
    }
  }
</style>
