import store from "../store";

import httpClient from './httpClient';
import { mapActions, mapGetters } from "vuex";

const END_POINT = '/';

export default {
    methods: {
        ...mapGetters(["getAccessToken"]),
        ...mapActions(["saveAccessToken","saveUserName","destroyStore"]),
        login: function (resolve, credentials) {
            httpClient.post(END_POINT + 'login', {
                username: credentials.username,
                password: credentials.password,
                coop: credentials.coop,
                ctr: credentials.ctr
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                resolve(false);
            });
        },
        resetPasswordApi: function (resolve, email) {
            httpClient.post(END_POINT + 'resetpassword', {
                email: email
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                resolve(false);
            });
        },
        sessionStatus: function (resolve) {
            httpClient.get(END_POINT + 'sessionStatus')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response.status === 401 && this.getAccessToken()){
                    this.saveAccessToken(null)
                    window.location.href = '/login';
                }
            });
        },
        logingWithUrl: function(resolve){
            //Recogemos el token pasado por url para poder hacer las peticiones
            let queryString = window.location.search;
            let urlParams   = new URLSearchParams(queryString);
            let auth_token  = localStorage.getItem('store-app-integrado') ? JSON.parse(localStorage.getItem('store-app-integrado')) : null;
            let token = urlParams.get('token') || (auth_token ? auth_token.access_token  :  null );
            this.saveAccessToken(token);

            //Recogemos el codigo de producto si llega
            let codigo_articulo = urlParams.get("codigo_articulo") || null;
            this.saveCodigoArticulo(codigo_articulo); 

            httpClient.get(END_POINT + 'datos-socio')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if(error.response.status === 401){
                    this.saveAccessToken(null)
                    this.destroyStore();
                    window.location.href = '/login';
                }
            });
        }
    }
}