<template>
    <div>
        <div class="mb-3" v-if="tipo == 'individual'"><h5>Pedido {{ parseInt(pedido.num_pedido_origen) }}</h5></div>
        <div>
            <span>Origen</span> | <span class="articulo_codigo_valor">{{ pedido.origen }}</span>
        </div>
        <div>
            <span>Fecha</span> | <span class="articulo_codigo_valor">{{ pedido.fecha | format_fecha }}</span>
        </div>
        <div class="mt-3 mb-3">
          <Table :cabeceras="cabeceras_visibles" :items="pedido.lineas">
              <template slot="cell(imagen2d)" slot-scope="prop">
                  <img v-if="prop.item && (pedido.tipo=='Int V2' || !pedido.tipo)" :src="mostrarImagen(prop.item.codigo_producto)" class="img">
                  <img v-else-if="prop.item" :src="mostrarImagen(prop.item.producto.codigo_nacional)" class="img">
              </template>
              <template slot="cell(descripcion)" slot-scope="prop" >
                  <span class="articulo_nombre">{{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.nombre_producto : prop.item.producto.descripcion }}</span><br>
                  <span class="articulo_codigo">Código</span> <span class="articulo_codigo_valor">{{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.codigo_producto : prop.item.producto.codigo_nacional }}</span>
              </template>
              <template slot="cell(pvu)" slot-scope="prop" v-if="(user && user.cooperativa.id_unnefar!=35 && user.cooperativa.id_unnefar!=20) || !user">
                  {{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.precio : prop.item.producto.pvu }} &euro;
              </template>
              <template slot="cell(cantidad_pedida)" slot-scope="prop">
                  <span class="cantidad_pedida">{{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.cantidad_pedida : prop.item.cantidad}}</span>
              </template>
              <template slot="cell(cantidad_servida)" slot-scope="prop">
                  <span class="cantidad_servida">{{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.cantidad_servida : prop.item.respuestas? prop.item.respuestas[prop.item.respuestas.length-1].cantidad_servida : '' }}</span>
              </template>
              <template slot="cell(plazo)" slot-scope="prop">
                  <span class="articulo_plazo">{{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.texto_respuesta : '' }}</span>
              </template>
              <template slot="cell(codigo_incidencia)" slot-scope="prop">
                  <span class="articulo_incidencia">{{ !pedido.tipo || pedido.tipo == 'Int V2'? incidenciaDescripcion(prop.item) : prop.item.respuestas? prop.item.respuestas[prop.item.respuestas.length-1].respuesta : '' }}</span>
              </template>
          </Table>
        </div>
    </div>
  </template>

  <script>
  import Table from "@/components/common/table";
  import {mapGetters, mapState} from "vuex";

    export default {
      name: "pedido-detalle",
      mixins: [],
      props: {
        pedido: Object,
        tipo:{
            type:String,
            default:""
        }
      },
      components: {
        Table
      },
      data: () => {
       return{
           cabeceras:[
                {
                    key:"imagen2d",
                    label:"Imagen",
                    class:"w-10"
                },
                {
                    key:"descripcion",
                    label:"Descripción",
                    class:"w-25"
                },
                {
                    key:"pvu",
                    class:"w-10",
                    label:"PVU"
                },
                {
                    key:"cantidad_pedida",
                    class:"w-5",
                    label:"Cant. Pedida"
                },
                {
                    key:"cantidad_servida",
                    class:"w-5",
                    label:"Cant. Servida"
                },
                {
                    key:"plazo",
                    class:"w-10",
                    label:"Plazo"
                },
                {
                    key:"codigo_incidencia",
                    class:"w-10",
                    label:"Incidencia"
                }
           ]
       }
    },
    computed:{
      ...mapState(['user']),
        themeColors(){
            return this.$root.$el.attributes["style"].value;
        },
        plazoPedido(){
            return this.plazo(this.pedido.plazo);
        },
      cabeceras_visibles(){
        if(this.user && (this.user.cooperativa.id_unnefar==35 || this.user.cooperativa.id_unnefar==20)){
          return this.cabeceras.filter(item => item.key != "pvu");
        } else {
          return this.cabeceras;
        }
      }
    },
      created() {
        console.log(this.pedido.lineas);
        console.log(this.pedido);
      },
    methods:{
        plazo(p){
            switch(p){
                case 'A':
                    return "SIGUIENTE REPARTO";
                case 'B':
                    return "ENTREGAMOS EN 24 HORAS";
                case 'C':
                    return "ENTREGRAMOS EN 48 HORAS";
                case 'D':
                    return "ENTREGAMOS EN M��S 48 HORAS";
            }
        },
        incidenciaDescripcion(item){

            if(item.incidencia){
                let codigo=`00${item.incidencia.codigo}`.slice(-2);
                return `${codigo} - ${item.incidencia.descripcion}`
            }else{
                return '';
            }

        },
        /*mostrarPrecio(item){
            if(item.producto && item.producto.pvu){
                return parseFloat(item.producto.pvu).toFixed(2).replace(".",",");
            }else{
                return parseFloat(item.producto.pvl).toFixed(2).replace(".",",");
            }
        },
        codigo_nacional(item){
            let valor=item.producto?.codigo_nacional || item.codigo_producto;
            return parseInt(valor);
        }*/
      mostrarImagen(cod_producto){
        return `https://repofarma.com/gallery/2d.php?cn=${cod_producto}`;
      }
    },

  };
  </script>
